<template lang="html">
  <div class="column">
    <h3 class="title">Banco do Depósito</h3>

    <div class="field">
      <label class="label is-normal">Selecione o Banco</label>
      <div class="select is-medium is-fullwidth">
        <select v-model="payment_info.bank">
          <option v-for="bank in banks" :value="bank.name">
            {{ bank.displayName }}
          </option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      payment_info: {
        type: Object,
        required: true
      },

      $v: {
        type: Object,
        required: true
      },

      banks: {
        type: Array,
        required: true
      }
    }
  }
</script>

<style lang="scss" scoped>
  .field > .label {
    font-weight: 400;
  }

  .title {
    color: #7F7F7F;
    font-size: 1.2rem;
    font-weight: 400;
    margin-bottom: 2rem;
  }
</style>
