<template lang="html">
  <div class="root">
    <Tab
      :creditCard="paymentType.creditCard"
      :boleto="paymentType.boleto"
      :debitOnline="paymentType.debitOnline"
      :pix="paymentType.pix"
      :hasBoleto="hasBoleto"
      :setActive="setActive">
    </Tab>

    <CreditCard
      v-if="paymentType.creditCard"
      :pagseguroSession="pagseguroSession"
      :cart="cart"
      :creditCard="creditCard"
      :$v="$v">
    </CreditCard>

    <Boleto
      v-if="paymentType.boleto"
      :pagseguroSession="pagseguroSession"
      :cart="cart"
      :$v="$v">
    </Boleto>

    <DebitOnline
      v-if="paymentType.debitOnline"
      :pagseguroSession="pagseguroSession"
      :cart="cart"
      :$v="$v">
    </DebitOnline>

    <Pix
      v-if="paymentType.pix"
      :pagseguroSession="pagseguroSession"
      :cart="cart"
      :$v="$v">
    </Pix>
  </div>
</template>

<script>
  import { mapActions, mapGetters, mapState } from 'vuex/dist/vuex.esm'
  import Tab from './Container/Tab'
  import CreditCard from './Container/CreditCard'
  import Boleto from './Container/Boleto'
  import DebitOnline from './Container/DebitOnline'
  import Pix from './Container/Pix'


  export default {
    props: {
      pagseguroSession: {
        type: String,
        required: true
      },

      paymentType: {
        type: Object,
        required: true
      },

      cart: {
        type: Object,
        required: true
      },

      creditCard: {
        type: Object,
        required: true
      },

      hasBoleto: {
        type: Boolean,
        required: true
      },

      $v: {
        type: Object,
        required: true
      }
    },


    components: {
      CreditCard,
      Boleto,
      DebitOnline,
      Pix,
      Tab
    },


    methods: {
      ...mapActions([
        'setFetching',
        'setMessage',
        'resetMessages',
      ]),

      setActive(val) {
        this.setFalse()

        switch(val) {
          case "creditCard":
            this.paymentType.creditCard = true
            this.cart.payment_info.type = 'credit_card'
            break
          case "boleto":
            if (this.hasBoleto) {
              this.paymentType.boleto = true
              this.cart.payment_info.type = 'boleto'
            } else {
              this.setActive('creditCard')
            }
            break
          case "debitOnline":
            this.paymentType.debitOnline = true
            this.cart.payment_info.type = 'debit_online'
            break
          case "pix":
            this.paymentType.pix = true
            this.cart.payment_info.type = 'pix'
            break
        }
      },

      setFalse() {
        this.paymentType.creditCard = false
        this.paymentType.boleto = false
        this.paymentType.debitOnline = false
        this.paymentType.pix = false
      },
    }
  }
</script>

<style lang="scss" scoped>
</style>
