import Sector from 'src/Models/Sectors/Model'
import * as TYPES from '../../types'

export default {
  [TYPES.SET_SECTORS](state, { sectors, eventTax, customerFee }) {
    state.all = sectors.map(sector =>
      new Sector(sector, eventTax, customerFee)
    )
  },

  [TYPES.CHANGE_SECTOR_VISIBLE](state, sectorId) {
    const sectors = state.all.map((sector) => {
      if (sector.id == sectorId) {
        sector.isVisible = !sector.isVisible
      }
      return sector
    })
    state.all = sectors
  },

  [TYPES.SET_PROMOTION_TO_TICKETS](state, promotion) {
    const sectors = state.all.map(sector => {
      sector.tickets.map(ticket => {
        if (promotion.ticket_ids.includes(ticket.id)) {
          ticket.promotion = {
            id: promotion.id,
            discount: promotion.discount,
            discount_type: promotion.discount_type,
          }
        }

        return ticket
      })

      return new Sector(sector, sector.eventTax, sector.customerFee)
    })

    state.all = sectors
  },
}
