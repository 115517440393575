<template lang="html">
  <div class="column">
    <h3 class="title">Informações do Cartão</h3>

    <div class="field" :class="{'has-error': $v.number.$error || $v.brand.$error}">
      <label class="label is-normal">Número do Cartão</label>
      <div class="control">
        <input
          class="input is-medium"
          :class="{'is-danger': $v.number.$error || $v.brand.$error}"
          type="number"
          v-model.trim="creditCard.number"
          @blur="getBrand()">
      </div>

      <span class="help is-danger" v-if="!$v.number.required">
        Não pode ficar em branco.
      </span>

      <span class="help is-danger" v-if="!$v.brand.required && $v.number.required">
        Número do cartão de crédito incorreto.
      </span>
    </div>

    <div class="field" :class="{'has-error': $v.date.$error}">
      <label class="label is-normal">Validade do Cartão</label>
      <div class="control">
        <input
          class="input is-medium"
          :class="{'is-danger': $v.date.$error}"
          type="text"
          v-model.trim="creditCard.date"
          @blur="$v.date.$touch()"
          placeholder="MM/YYYY"
          v-mask="'##/####'">
      </div>

      <span class="help is-danger" v-if="!$v.date.required">
        Não pode ficar em branco.
      </span>

      <span class="help is-danger" v-if="!$v.date.isDate">
        Não é uma data válida.
      </span>
    </div>

    <div class="field" :class="{'has-error': $v.cvv.$error}">
      <label class="label is-normal">Código de Segurança</label>
      <div class="control">
        <input
          class="input is-medium"
          :class="{'is-danger': $v.cvv.$error}"
          type="number"
          @blur="$v.cvv.$touch()"
          placeholder="CVV"
          v-model.trim="creditCard.cvv">
      </div>

      <span class="help is-danger" v-if="!$v.cvv.required">
        Não pode ficar em branco.
      </span>
    </div>

    <div class="field">
      <label class="label is-normal">Parcelas em</label>
      <div class="select is-medium is-fullwidth">
        <select :disabled="brandIsEmpty" v-model="cart.payment_info.installment">
          <option v-for="installment in listInstallment" :value="installment">
            {{ getInstallmentText(installment) }}
          </option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
  import { toCurrency } from 'utils/currency'
  import { isEmpty } from 'lodash'

  export default {
    props: {
      installments: {
        type: Object,
        required: true
      },

      cart: {
        type: Object,
        required: true
      },

      creditCard: {
        type: Object,
        required: true
      },

      $v: {
        type: Object,
        required: true
      }
    },


    computed: {
      brandIsEmpty() {
        return isEmpty(this.creditCard.brand)
      },

      listInstallment() {
        return this.installments[this.creditCard.brand]
      }
    },


    methods: {
      getInstallmentText(obj) {
        return `${obj.quantity}x de ${toCurrency(obj.installmentAmount)} = ${toCurrency(obj.totalAmount)}`
      },

      getBrand() {
        this.$v.number.$touch()

        PagSeguroDirectPayment.getBrand({
          cardBin: this.creditCard.number,
          success: (response) => {
            this.creditCard.brand = response.brand.name
          },
          error: (error) => {
            console.warn('getBrand', error)
            this.$v.brand.$touch()
          },
      });
      }
    }
  }
</script>

<style lang="scss" scoped>
  .field > .label {
    font-weight: 400;
  }

  .title {
    color: #7F7F7F;
    font-size: 1.2rem;
    font-weight: 400;
    margin-bottom: 2rem;
  }

  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }
</style>
