const tags = [
    'lote',
    'prevenda',
    'pre-venda',
    'pre venda',
    'prévenda',
    'pré-venda',
    'pré venda'
];

export function shouldHideLot(ticketName) {
    if (ticketName == null) {
        return false;
    }
    const tname = ticketName.toLowerCase();
    return tags.some(tag => tname.includes(tag));
}