<template lang="html">
  <div class="ticket-card-purchase-actions">
    <a v-if="!isShared"
        title="Compartilhar Ingresso"
        target="_blank"
        class="action"
        v-clipboard:copy="ticket_url"
        v-clipboard:success="copySuccess"
        v-clipboard:error="copyError">
      <svg xmlns="http://www.w3.org/2000/svg" width="100px" height="100px" viewBox="0 0 100 100" class="icon is-medium">
        <path d="M70 59c-1,0 -1,-1 -2,-1 -1,-1 -1,-3 0,-4l23 -22c6,-6 6,-16 0,-23 -7,-6 -17,-6 -23,0l-22 23c-1,1 -3,1 -4,0 -1,-1 -1,-2 0,-3l23 -23c8,-8 21,-8 29,0 8,8 8,21 0,29l-22 23c-1,0 -2,1 -2,1z"></path> <path d="M21 100c-6,0 -11,-2 -15,-6 -4,-4 -6,-9 -6,-15 0,-5 2,-10 6,-14l23 -23c0,-1 2,-1 3,0 1,1 1,3 0,4l-23 22c-3,3 -4,7 -4,11 0,5 1,9 4,12 3,3 7,4 12,4 0,0 0,0 0,0 4,0 8,-1 11,-4l22 -23c1,-1 3,-1 4,0 1,1 1,3 0,4l-23 22c-4,4 -9,6 -14,6z"></path>
        <path d="M24 77c0,0 -1,0 -1,0 -1,-1 -1,-3 0,-4l49 -49c1,-1 2,-1 3,0 1,1 1,3 0,4l-49 49c0,0 -1,0 -2,0z"></path>
      </svg>
    </a>

    <a title="Imprimir Ingresso" class="action" @click.prevent="printer">
      <svg xmlns="http://www.w3.org/2000/svg" width="100px" height="100px" viewBox="0 0 100 100" class="icon is-medium">
        <path d="M97 18l-9 0 0 -16c0,-1 -1,-2 -2,-2l-69 0c-1,0 -2,1 -2,2l0 16 -12 0c-1,0 -2,1 -2,2l0 52c0,1 1,2 2,2l18 0 0 24c0,1 0,2 1,2l59 0c1,0 1,-1 1,-2l0 -24 15 0c1,0 2,-1 2,-2l0 -52c-1,-1 -1,-2 -2,-2zm-79 -15l67 0 0 15 -67 0 0 -15zm6 94l0 -38 55 0 0 38 -55 0 0 0zm71 -26l-13 0 0 -12 5 0c1,0 1,-1 1,-2 0,-1 0,-1 -1,-1l-71 0c-1,0 -1,0 -1,1 0,1 0,2 1,2l5 0 0 12 -16 0 0 -50 90 0 0 50 0 0 0 0zm-26 -24l17 0c1,0 2,-1 2,-2l0 -11c0,0 -1,-1 -2,-1l-17 0c-1,0 -1,1 -1,1l0 11c0,1 0,2 1,2zm2 -11l14 0 0 7 -14 0 0 -7zm1 36c0,1 -1,2 -1,2l-39 0c-1,0 -1,-1 -1,-2 0,-1 0,-1 1,-1l39 0c0,0 1,0 1,1zm0 11c0,1 -1,2 -1,2l-39 0c-1,0 -1,-1 -1,-2 0,0 0,-1 1,-1l39 0c0,0 1,1 1,1z">
        </path>
      </svg>
    </a>

    <a v-if="!isShared" title="Editar Participante" class="action" @click.prevent="$refs.ParticipantForm.openModal">
      <svg xmlns="http://www.w3.org/2000/svg" width="100px" height="100px" viewBox="0 0 100 100" class="icon is-medium">
        <path d="M5 69l-1 1 -4 30 30 -4 70 -70 -26 -26 -69 69zm24 25l-26 3 4 -26 50 -51 23 23 -51 51zm53 -53l-23 -22 15 -15 22 22 -14 15z">
        </path>
      </svg>
    </a>

    <a v-if="isSold && hasCertificate" title="Baixar Certificado" class="action" :href="ticket_certificate_url" target="_blank">
      <svg xmlns="http://www.w3.org/2000/svg" width="500px" height="500px" viewBox="0 0 500 500" class="icon is-medium">
        <path d="M500 373l0 -333c0,-19 -16,-35 -35,-35l-430 0c-19,0 -35,16 -35,35l0 333c0,20 16,36 35,36l232 0c1,1 3,3 4,4l0 82 61 -30 62 30 0 -82c1,-1 3,-3 4,-4l67 0c19,0 35,-16 35,-36zm-115 36c-15,11 -33,17 -53,17 -19,0 -37,-6 -52,-17 0,0 0,0 0,0 -3,-3 -6,-5 -9,-8 0,0 0,-1 -1,-1 -16,-16 -25,-38 -25,-62 0,-48 39,-87 87,-87 49,0 88,39 88,87 0,24 -10,46 -25,62 -1,0 -1,1 -1,1 -3,3 -6,5 -9,8 0,0 0,0 0,0zm0 72l-53 -25 -52 25 0 -62c15,10 33,16 52,16 20,0 38,-6 53,-16l0 62zm80 -81l-59 0c14,-17 23,-38 23,-62 0,-53 -43,-96 -97,-96 -53,0 -96,43 -96,96 0,24 8,45 22,62l-223 0c-14,0 -26,-12 -26,-27l0 -333c0,-14 12,-26 26,-26l430 0c14,0 26,12 26,26l0 333c0,15 -12,27 -26,27z"/>
        <rect x="119" y="91" width="261.125" height="8.67053"/>
        <rect x="93" y="170" width="313.506" height="8.67053"/>
        <rect x="121" y="280" width="54.9762" height="8.67053"/>
      </svg>
    </a>

    <ParticipantForm
      ref="ParticipantForm"
      :ticketItemId="ticket_item_id"
      :data="participant">
    </ParticipantForm>
  </div>
</template>

<script>
  import { mapActions, mapGetters, mapState } from 'vuex/dist/vuex.esm'
  import ParticipantForm from './Participant/Form'
  import jsPDF from 'jspdf'
  import html2canvas from 'html2canvas'


  export default {
    name: 'ticket-actions',

    data() {
      return {
        isVisible: false,
        title: 'Editar Participante',
        isShared: JSON.parse(this.shared),
        isSold: JSON.parse(this.ticket_item_sold),
        hasCertificate: JSON.parse(this.has_certificate),
      }
    },

    props: {
      printer_id: {
        type: String,
        required: true,
      },

      style_url: {
        type: String,
        required: true,
      },

      ticket_item_id: {
        type: String,
        required: true,
      },

      participant: {
        type: String,
        required: true,
      },

      shared: {
        type: String,
        required: true,
      },

      ticket_url: {
        type: String,
        required: true,
      },

      ticket_certificate_url: {
        type: String,
        required: true,
      },

      ticket_item_sold: {
        type: String,
        required: true,
      },

      has_certificate: {
        type: String,
        required: true,
      },
    },

    components: {
      ParticipantForm,
    },

    methods: {
      ...mapActions([
        'setMessage',
        'resetMessages',
      ]),

      printer() {
        const ticket = document.getElementById(this.printer_id)

        const pdf = new jsPDF({
          orientation: 'portrait',
          format: 'a4',
        })


        html2canvas(ticket, {
          background :'#FFFFFF',
          allowTaint: false,
          useCORS: true,
          scale: 1.2,
        }).then(canvas => {
          const imgData = canvas.toDataURL({
            format: 'png',
            multiplier: 0,
            enableRetinaScaling: true,
          })

          pdf.addImage(imgData, 'png', 10, 10)
          pdf.save("ticket.pdf")
        })
      },

      copySuccess(e) {
        this.setMessage({
          type: 'success',
          message: 'Link de compartilhamento do ingresso copiado com sucesso.'
        })
      },

      copyError(e) {
        this.setMessage({
          type: 'danger',
          message: 'Ops! Ocorreu um erro ao copiar o link do ingresso.'
        })
      },
    }
  }
</script>

<style lang="scss">
</style>
