// import Rails from "@rails/ujs"
// import Turbolinks from "turbolinks"
// import * as ActiveStorage from "@rails/activestorage"

import Vue               from 'vue/dist/vue.esm'
import Vuelidate         from 'vuelidate'
import store             from 'src/Application/Vuex'
import httpPlugin        from 'plugins/http'
import SocialSharing     from 'vue-social-sharing'
import TurbolinksAdapter from 'vue-turbolinks'
import VueLazyLoad       from 'vue-lazyload'
import VueScrollTo       from 'vue-scrollto'
import VueTheMask        from 'vue-the-mask'
import VueClipboard      from 'vue-clipboard2'
import { fabric }        from 'fabric'
// import '../utils/eu-banner'
import '../utils/clipboard'
import '../utils/collapsible'

// Rails.start()
// Turbolinks.start()
// ActiveStorage.start()

Vue.use(VueClipboard)
Vue.use(VueTheMask)
Vue.use(httpPlugin, { store })
Vue.use(Vuelidate)
Vue.use(SocialSharing)
Vue.use(TurbolinksAdapter)
Vue.use(VueLazyLoad)
Vue.use(VueScrollTo, {
  container: "body",
  duration: 500,
  easing: "ease",
  offset: 0,
  cancelable: true,
  onDone: false,
  onCancel: false,
  x: false,
  y: true
})

import CreateOrganizerForm       from 'Organizerland/organizer/CreateOrganizerForm'

import ShoppingCartButton        from 'src/Application/Events/ShoppingCart/Button'
import DropdownToggleMobile      from 'src/Application/NavBar/DropdownToggleMobile'
import DropdownToggleDesktop     from 'src/Application/NavBar/DropdownToggleDesktop'
import ShoppingCart              from 'src/Application/ShoppingCart/ShoppingCart'
import UpdateAvatar              from 'src/Application/Users/Avatar/Update'
import Enrollment                from 'src/Application/Enrollments/Enrollment'
import TicketActions             from 'src/Application/Users/Tickets/Actions'
import FooterLinks               from 'src/Application/Footer/Links'
import NavbarSearch              from 'src/Application/NavBar/NavbarSearch'
import HeroSearch                from 'src/Application/NavBar/HeroSearch'
import Certificate               from 'src/Application/Users/Certificates/Certificate'

import GoogleMap                 from 'src/Shared/GoogleMap'
import Photobox                  from 'src/Shared/Photobox'
import SocialShare               from 'src/Shared/SocialShare'
import ButtonSocialShare         from 'src/Shared/SocialShare/Button'

import preventScroll             from 'src/Utils/preventScroll'
import Alert                     from 'src/Shared/Alert'


document.addEventListener('turbolinks:load', () => {
  var element = document.getElementById("app")
  preventScroll(false, document.documentElement)

  var vueapp = new Vue({
    el: element,
    store,
    components: {
      UpdateAvatar,
      CreateOrganizerForm,
      SocialShare,
      ButtonSocialShare,
      ShoppingCartButton,
      Photobox,
      DropdownToggleMobile,
      DropdownToggleDesktop,
      GoogleMap,
      ShoppingCart,
      Alert,
      Enrollment,
      TicketActions,
      FooterLinks,
      NavbarSearch,
      HeroSearch,
      Certificate,
    }
  })
})
