import { http } from 'plugins/http'
import { getData } from 'utils/get'
import * as TYPES from '../../types'

export default {
  fetchPromotion({ commit }, { eventId, promotionCode }) {
    return http.get(`/spa/events/${eventId}/promotions/${promotionCode}`)
      .then(getData)
      .then(data => {
        commit(TYPES.SET_CURRENT_PROMOTION, data)
        commit(TYPES.SET_MAX_PURCHASE_TO_CURRENT_CART, data)
        commit(TYPES.CLEAN_CART_TICKETS_FROM_CURRENT_CART)
        commit(TYPES.SET_PROMOTION_TO_TICKETS, data)
        return Promise.resolve(data)
      })
      .catch(() => Promise.reject('FAIL_IN_FETCH_PROMOTION'))
  }
}
