<template lang="html">
  <div class="root">
    <div class="notification is-warning">
      <p>Ao finalizar a sua compra, você terá um Qrcode para pagamento válido por 1 hora.</p>
    </div>

    <div class="columns">
      <DocumentForm
        :holder="cart.holder"
        :$v="$v.cart.holder">
      </DocumentForm>

    </div>
  </div>
</template>

<script>
  import DocumentForm from '../Forms/DocumentForm'

  export default {
    props: {
      pagseguroSession: {
        type: String,
        required: true
      },

      cart: {
        type: Object,
        required: true
      },

      $v: {
        type: Object,
        required: true
      }
    },

    components: {
      DocumentForm
    },

    mounted() {
      PagSeguroDirectPayment.setSessionId(this.pagseguroSession)
    },
  }
</script>
