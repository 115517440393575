import { http } from 'plugins/http'
import { getData } from 'utils/get'
import * as TYPES from '../../types'

export default {
  fetchSectors({ commit }, { eventId, eventTax, customerFee }) {
    return http.get(`/spa/events/${eventId}/sectors`)
      .then(getData)
      .then(data => {
        commit(TYPES.SET_SECTORS, {
          sectors: data,
          eventTax: eventTax,
          customerFee: customerFee,
        })

        return Promise.resolve(data)
      })
      .catch(() => Promise.reject('FAIL_IN_FETCH_SECTORS'))
  },

  changeSectorVisible({ commit }, { sectorId }) {
    commit(TYPES.CHANGE_SECTOR_VISIBLE, sectorId)
  },
}
