import { isEmpty } from 'lodash'
import { toCurrency } from 'utils/currency'
import CartTicket from 'src/Models/CartTickets/Model'
import Payment from 'src/Models/Payments/Model'
import schema from './Schema'

export default class Cart {
  constructor(data = {}) {
    Object.assign(this, schema, data)
    this.cart_tickets_attributes = this.setCartTickets()
  }

  get isCharged() {
    return this.priceTotal > 0
  }

  get priceTotal() {
    return this.cart_tickets_attributes.reduce(this.sumCartTotal, 0.00) + this.taxTotal
  }

  get taxTotal() {
    if (this.customerFee) {
      return this.cart_tickets_attributes.reduce(this.sumTax, 0.00)
    } else {
      return 0.00
    }
  }

  get priceTotalCurrency() {
    return toCurrency(this.priceTotal)
  }

  get countCartTickets() {
    return this.cart_tickets_attributes.length
  }

  get hasReachedMaxPurchase() {
    return this.countCartTickets >= this.maxPurchase
  }

  get hasCartTickets() {
    return !isEmpty(this.cart_tickets_attributes)
  }

  setCartTickets() {
    if (this.hasCartTickets) {
      return this.cart_tickets_attributes.map(cartTicket => new CartTicket(cartTicket))
    } else {
      return []
    }
  }

  sumTax(prevVal, ticket) {
    return prevVal + parseFloat(ticket.fee_amount)
  }

  sumCartTotal(prevVal, ticket) {
    return prevVal + parseFloat(ticket.priceTotal)
  }
}
