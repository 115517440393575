<template lang="html">
  <button @click="createCart"
          type="button"
          class="button is-large is-primary"
          :class="{'is-loading': isFetching}"
          :disabled="buttonDisabled">

    <span
      class="icon-amount"
      :class="{'is-invisible': isFetching}">
      <svg class="icon" xmlns="http://www.w3.org/2000/svg" width="100px" height="100px" viewBox="0 0 100 100">
        <path d="M48 75c4,0 7,1 9,3 2,2 3,5 3,9 0,3 -1,6 -3,8 -2,2 -5,4 -9,4 -3,0 -6,-2 -8,-4 -2,-2 -4,-5 -4,-8 0,-4 2,-7 4,-9 2,-2 5,-3 8,-3l0 0zm5 6c-1,-1 -3,-2 -5,-2 -2,0 -4,1 -5,2 -1,2 -2,4 -2,6 0,2 1,3 2,5 1,1 3,2 5,2 2,0 4,-1 5,-2 2,-2 3,-3 3,-5 0,-2 -1,-4 -3,-6z"/>
        <path d="M78 75c3,0 6,1 8,3 3,2 4,5 4,9 0,3 -1,6 -4,8 -2,2 -5,4 -8,4 -3,0 -6,-2 -9,-4 -2,-2 -3,-5 -3,-8 0,-4 1,-7 3,-9 3,-2 6,-3 9,-3l0 0zm5 6c-1,-1 -3,-2 -5,-2 -2,0 -4,1 -5,2 -2,2 -2,4 -2,6 0,2 0,3 2,5 1,1 3,2 5,2 2,0 4,-1 5,-2 1,-2 2,-3 2,-5 0,-2 -1,-4 -2,-6z"/>
        <path d="M46 64l34 0c3,0 4,-1 5,-3 2,-3 16,-33 7,-33l-65 0 14 32c1,2 2,4 5,4l0 0 0 0zm-32 -54l-12 -3c-3,-1 -2,-5 1,-5l13 3c1,0 1,1 2,2l7 16 67 0c17,0 1,33 -3,40 -1,3 -5,5 -9,5l-34 0c-4,0 -8,-2 -9,-6 -8,-17 -16,-35 -23,-52l0 0z"/>
      </svg>

      <span>{{ cartTotal }}</span>
    </span>

    <span>{{ textBuyer }}</span>
  </button>
</template>

<script>
  export default {
    props: {
      cartTotal: {
        type: String,
        required: true
      },

      buttonDisabled: {
        type: Boolean,
        required: true,
        default: true
      },

      isFetching: {
        type: Boolean,
        required: true,
        default: false
      },

      createCart: {
        type: Function,
        required: true
      },

      textBuyer: {
        type: String,
        required: true
      },
    }
  }
</script>

<style lang="scss" scoped>
  .button {
    display: flex !important;
    align-items: center;
    justify-content: space-between;
    min-width: 15rem;

    > .icon-amount {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 1rem;
    }

    @media screen and (max-width: 768px) {
      & {
        position: fixed;
        width: 100%;
        bottom: 0;
        z-index: 10;
        right: 0;
        border-radius: unset;
      }
    }
  }

  .icon {
    fill: #FFFFFF;
  }
</style>
