<template lang="html">
  <div ref="overlay" class="overlay-container" :class="classOverlay">
    <div class="overlay-header">
      <h4 class="overlay-title">{{ this.title }}</h4>
      <a class="overlay-close" @click="closeOverlay">&times;</a>
    </div>

    <div class="overlay-content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
  import preventScroll from 'src/Utils/preventScroll'
  import preventiOSScroll from 'src/Utils/preventiOSScroll'


  export default {
    name: 'overlay',


    mounted: function () {
      document.addEventListener("keydown", (e) => {
        if (this.isVisible && e.keyCode == 27) {
          this.closeOverlay()
        }
      })
    },


    watch: {
      isVisible: function(val) {
        preventiOSScroll(this.$refs.overlay)
        preventScroll(val, document.documentElement)
      }
    },


    props: {
      isVisible: {
        type: Boolean,
        required: true,
        default: false
      },

      closeOverlay: {
        type: Function,
        required: true
      },

      title: {
        type: String,
      }
    },


    computed: {
      classOverlay: function() {
        return {
          "-visible": this.isVisible,
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .overlay-container {
    visibility: hidden;
    position: fixed;
    opacity: 0;
    width: 100vw;
    height: 100vh;
    z-index: 10;
    left: 0;
    top: 0;
    background-color: rgb(255, 255, 255);
    overflow-x: hidden;
    transition: opacity 304ms ease-in-out;
    padding: 1rem;
    -webkit-overflow-scrolling: touch;

    &.-visible {
      visibility: visible;
      opacity: 1;
    }
  }

  .overlay-header {
    position: fixed;
    width: calc(100% - 2rem);
    background: white;
    top: 0;
    padding-top: 1rem;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 10;
  }

  .overlay-title {
    font-size: 1.5rem;
    font-weight: 100;
  }

  .overlay-close {
    color: #717274;
    font-size: 2.2rem;
    width: 2rem;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .overlay-content {
    position: relative;
    padding-top: 50px;
    margin-bottom: 25vh;
    z-index: 9;

    > .root.-loading {
      height: 20rem;
    }
  }
</style>
