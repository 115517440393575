<template lang="html">
  <form @submit.prevent="searchEvent" class="root form-search">
    <div class="field">
      <div class="control has-icons-left">
        <input
          class="input is-large"
          type="text"
          placeholder="Pesquise por eventos"
          v-model="filter.search">
          <span class="icon is-small is-left">
            <svg xmlns="http://www.w3.org/2000/svg" width="100px" height="100px" viewBox="0 0 100 100" class="icon is-small">
              <path d="M17 16c-1,1 -1,3 0,4 1,1 2,1 3,0 11,-12 30,-12 41,0 0,0 1,0 1,0 1,0 2,0 2,0 1,-1 1,-3 0,-4 -13,-13 -34,-13 -47,0z"></path>
              <path d="M98 88l-13 -13c-2,-2 -5,-3 -8,-2 0,0 0,0 0,0l-7 -6c15,-16 14,-40 -1,-56 -16,-15 -41,-15 -57,0 -16,16 -16,42 0,57 8,8 18,12 28,12 10,0 19,-3 27,-10l6 6c-1,3 -1,7 2,8l13 14c1,1 3,2 5,2 1,0 3,-1 5,-2 3,-3 3,-7 0,-10l0 0 0 0zm-83 -23c-14,-14 -14,-37 0,-50 7,-7 16,-11 25,-11 10,0 18,4 25,11 15,13 15,36 1,50 -14,14 -37,14 -51,0l0 0zm80 30c-1,1 -3,1 -4,0l-13 -13c-1,-1 -1,-3 0,-4 1,0 1,0 2,0 0,0 1,0 1,0l14 13c0,1 0,3 0,4l0 0z"></path>
            </svg>
          </span>
      </div>
    </div>

    <div class="field">
      <div class="control has-icons-left">
        <div class="select is-large is-fullwidth">
          <select v-model="filter.by_city">
            <option value="">Em qualquer lugar</option>
            <option v-for="city in cities" :value="city.slug">
              {{ city.name }}
            </option>
          </select>
        </div>

        <div class="icon is-small is-left">
          <svg xmlns="http://www.w3.org/2000/svg" width="100px" height="100px" viewBox="0 0 100 100" class="icon is-small">
            <path d="M50 100l1 -1c2,-2 38,-39 38,-60 0,-21 -18,-39 -39,-39 -21,0 -39,18 -39,39 0,21 36,58 38,60l1 1zm0 -96c19,0 35,16 35,35 0,17 -28,49 -35,56 -7,-7 -35,-39 -35,-56 0,-19 16,-35 35,-35z"/>
            <path d="M71 39c0,-12 -9,-20 -21,-20 -12,0 -21,8 -21,20 0,11 9,21 21,21 12,0 21,-10 21,-21zm-38 0c0,-9 7,-17 17,-17 10,0 17,8 17,17 0,10 -7,17 -17,17 -10,0 -17,-7 -17,-17z"/>
          </svg>

        </div>
      </div>
    </div>

    <div class="field">
      <div class="control has-icons-left">
        <div class="select is-large is-fullwidth">
          <select v-model="filter.by_period">
            <option value="">Todas as datas</option>
            <option v-for="period in periods" :value="period.value">
              {{ period.name }}
            </option>
          </select>
        </div>

        <div class="icon is-small is-left">
          <svg xmlns="http://www.w3.org/2000/svg" width="100px" height="100px" viewBox="0 0 100 100" class="icon is-small">
             <path  d="M94 18c0,-5 -4,-8 -8,-8l-15 0 0 -10 -3 0 0 10 -36 0 0 -10 -3 0 0 10 -14 0c-5,0 -9,3 -9,8l0 74c0,5 4,8 9,8l71 0c4,0 8,-3 8,-8l0 -74 0 0zm-79 -5l14 0 0 10 3 0 0 -10 36 0 0 10 3 0 0 -10 15 0c3,0 5,2 5,5l0 21 -81 0 0 -21c0,-3 2,-5 5,-5l0 0 0 0zm71 84l-71 0c-3,0 -5,-2 -5,-5l0 -50 81 0 0 50c0,3 -2,5 -5,5l0 0z"/>
             <rect  x="35" y="76" width="29.0687" height="3.22695"/>
             <rect  x="35" y="60" width="29.0687" height="3.22695"/>
          </svg>
        </div>
      </div>
    </div>

    <div class="is-hidden-tablet">
      <button
        @click.prevent="searchEvent"
        type="button"
        class="button is-large is-primary">
        BUSCAR
      </button>
    </div>

    <div class="is-hidden-mobile">
      <button
        @click.prevent="searchEvent"
        type="button"
        class="button is-large is-outlined is-info is-hidden-mobile">
        BUSCAR
      </button>
    </div>
  </form>
</template>

<script>
  import { isEmpty, omitBy} from 'lodash'

  export default {
    data() {
      return {
        filter: {
          search: '',
          by_city: '',
          by_period: '',
        },

        periods: [
          { name: 'Hoje', value: 'today' },
          { name: 'Amanhã', value: 'tomorrow' },
          { name: 'Esta semana', value: 'week' },
          { name: 'Este fim de semana', value: 'weekend' },
          { name: 'Próxima semana', value: 'next_week' },
          { name: 'Este mês', value: 'month' },
        ]
      }
    },

    props: {
      cities: {
        type: Array,
        required: true
      },

      params: {
        type: Object,
        required: true
      }
    },

    mounted() {
      this.filter.search = this.params.search || ''
      this.filter.by_city = this.params.by_city || ''
      this.filter.by_period = this.params.by_period || ''
    },

    methods: {
      searchEvent() {
        Turbolinks.visit(`/eventos?${this.buildParams()}`)
      },

      buildParams() {
        const filter = omitBy(this.filter, isEmpty)
        return Object.entries(filter).map(([key, val]) => `${key}=${val}`).join('&')
      }
    }
  }
</script>

<style lang="scss" scoped>
</style>
