import schema from './Schema'

export default class Payment {
  constructor(price = 0) {
    Object.assign(this, schema)
    this.installment.installmentAmount = parseFloat(price)
    this.installment.totalAmount = parseFloat(price)
  }

  get isCreditCard() {
    return this.isPaymentType('credit_card')
  }

  get isBoleto() {
    return this.isPaymentType('boleto')
  }

  get isDebitOnline() {
    return this.isPaymentType('debit_online')
  }

  get isPix() {
    return this.isPaymentType('pix')
  }

  isPaymentType(type) {
    return type == this.type
  }
}
