import { http } from 'plugins/http'
import { getData } from 'utils/get'
import * as TYPES from '../../types'

export default {
  fetchCertificate({ commit }, { ticketItemId }) {
    return http.get(`/spa/shareland/ticket_items/${ticketItemId}/certificates`)
    .then(getData)
    .then(data => Promise.resolve(data))
    .catch(() => Promise.reject('FAIL_IN_FETCH_CERTIFICATE'))
  },
}
