<template lang="html">
  <div @click="openUpload" class="avatar-update" :class="{ '-uploading': isUploading }">
    <Container
      :avatar_url="avatar_url"
      :name="name"
      :name_symbol="name_symbol"
      :isFetching="isFetching"
      :classes="classes">
    </Container>

    <div class="overlay">
      <span>{{ upload_status }}</span>
    </div>

    <input @change="handleUploadAvatar" ref="fileInput" accept="image/*"
      class="input-upload" name="avatar" type="file">
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import Container from './Avatar/Container'


  export default {
    name: "avatar",


    data() {
      return {
        upload_status: "Mudar",
        isUploading: false,
        old_avatar_url: "",
      }
    },


    props: {
      avatar_url: {
        type: String,
        required: true
      },

      name: {
        type: String,
        required: true
      },

      name_symbol: {
        type: String,
        required: true
      },

      updateResource: {
        type: Function,
        required: true
      },

      classes: {
        type: String
      },
    },


    components: {
      Container
    },


    computed: {
      ...mapGetters([
        'isDisabled',
        'isFetching',
      ]),
    },


    methods: {
      ...mapActions([
        'setFetching',
      ]),

      handleUploadAvatar(event) {
        this.previewAvatar(event.target)
          .then(data => this.uploadAvatar())
          .catch(error => console.warn(error))
      },

      uploadAvatar() {
        this.setStep('sending')
        this.setFetching({ fetching: true })
        this.updateResource({
          avatar: this.avatar_url
        }).then(data => {
          this.setStep('sent')
          setTimeout(this.setStep, 5000, 'initial')
          this.setFetching({ fetching: false })
        })
        .catch((error) => {
          this.setStep('error')
          setTimeout(this.setStep, 5000, 'initial')
          this.setFetching({ fetching: false })
          this.avatar_url = this.old_avatar_url
          console.warn(error)
        })
      },

      previewAvatar(target) {
        return new Promise((resolve, reject) => {
          if (target.files && target.files[0]) {
            this.setStep('loading')
            const reader = new FileReader();
            reader.readAsDataURL(target.files[0]);

            reader.onload = (e) => {
              this.old_avatar_url = this.avatar_url
              this.avatar_url = e.target.result
              return resolve(e);
            }

            reader.onerror = (e) => {
              this.setStep('error')
              setTimeout(this.setStep, 5000, 'initial')
              return reject(e);
            }
          }
        })
      },

      openUpload() {
        this.$refs.fileInput.click()
      },

      setStep(step) {
        switch(step) {
          case "loading":
            this.isUploading = true;
            this.upload_status = 'Carregando...';
            break;
          case "sending":
            this.isUploading = true;
            this.upload_status = 'Enviando...';
            break;
          case "sent":
            this.isUploading = true;
            this.upload_status = 'Enviado!';
            break;
          case "error":
            this.isUploading = true;
            this.upload_status = 'Erro!';
            break;
          default:
            this.isUploading = false;
            this.upload_status = 'Mudar!';
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .avatar-update {
    position: relative;
    cursor: pointer;

    > .overlay {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, .5);
      opacity: 0;
      transition: opacity 304ms linear;
    }

    .overlay > span {
      color: #FFFFFF;
      font-weight: 500;
    }

    &.-uploading > .overlay {
      opacity: 1;
      transition-delay: 0s;
    }


    @media screen and (min-width: 1024px) {
      &:hover > .overlay {
        opacity: 1;
        transition-delay: 0s;
      }
    }
  }

  .input-upload {
    display: none;
  }
</style>
