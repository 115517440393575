document.addEventListener('DOMContentLoaded', () => {

  window.toggleCollapsible = function(elementId) {
    let clickable = document.getElementById(elementId);
    let el_owner = document.getElementById(`ticket_owner_${elementId}`);
    let el_participants = document.getElementById(`ticket_participants_${elementId}`);
    let all_collapsible = [...document.querySelectorAll('.collapsible')];

    if (el_owner.className !== 'collapsible show') {
      all_collapsible.map((el) => {
        el.className = 'collapsible';
      });
    }

    if (el_owner.className == 'collapsible') {
      el_owner.className = 'collapsible show';
      el_participants.className = 'collapsible show';
    } else {
      all_collapsible.map((el) => {
        el.className = 'collapsible';
      });
    }

    el_owner.scrollIntoView();
  }
});
