<template lang="html">
  <div class="ticket">
    <div class="header">
      <svg class="icon is-medium" xmlns="http://www.w3.org/2000/svg" width="100px" height="100px" viewBox="0 0 100 100">
        <path d="M98 32l-7 -7c0,0 -1,0 -2,0 -2,2 -5,3 -7,3 -3,0 -6,-1 -7,-3 -4,-4 -4,-10 0,-14 0,-1 0,-2 0,-2l-7 -7c-3,-3 -6,-3 -9,0l-57 57c-3,3 -3,6 0,9l7 7c0,0 1,0 2,0 4,-4 10,-4 14,0 4,3 4,10 0,14 0,1 0,2 0,2l7 7c1,1 3,2 5,2 1,0 3,-1 4,-2l57 -57c3,-2 3,-6 0,-9zm-2 7l-40 39c0,0 0,0 0,0l-7 -7c-1,-1 -2,-1 -3,0 0,1 0,2 0,3l7 7c0,0 1,0 1,0l-15 15c-1,1 -3,1 -4,0l-6 -6c4,-5 4,-13 -1,-18 -5,-4 -13,-5 -18,-1l-5 -6c-2,-1 -2,-2 0,-3l14 -15 6 5c1,1 1,1 1,1 1,0 1,0 2,-1 0,0 0,-1 0,-2l-6 -6 40 -40c1,-1 3,-1 4,0l5 6c-4,5 -3,13 1,18 3,2 6,4 10,4 3,0 6,-1 8,-3l6 6c1,1 1,3 0,4l0 0z"/>
        <path d="M34 57c0,0 -1,0 -2,0 -1,1 -1,2 0,2l7 7c0,1 1,1 1,1 1,0 1,0 1,-1 1,0 1,-1 0,-2l-7 -7 0 0z"/>
      </svg>

      <div class="info">
        <p class="name">{{ ticketName }}</p>
        <p class="price">{{ ticketPrice }}</p>
      </div>
    </div>

    <div class="field-body">
      <div
        v-for="field in ticket.participant"
        :class="{'has-error': $v.participant[tIndex][field.token].value.$error }"
        class="field is-narrow">

        <label class="label is-normal">
          {{ field.title }}

          <span
            v-if="field.help"
            class="pl-tooltip-help tooltip is-tooltip-multiline"
            :data-tooltip="field.help">
            <span class="pl-tooltip-text">?</span>
          </span>
        </label>

        <div class="control">
          <!-- Using the input mask -->
          <input
            v-if="field.isMasked && !field.isSelect"
            class="input is-medium"
            :class="{'is-danger': $v.participant[tIndex][field.token].value.$error }"
            :type="field.kind"
            :placeholder="field.placeholder"
            @blur="$v.participant[tIndex][field.token].value.$touch()"
            v-mask="field.masked"
            v-model.trim="field.value">

          <span v-if="field.isSelect" class="select is-medium is-fullwidth">
            <select
              v-model="field.value"
            >
              <option value="" disabled selected>Selecione {{ field.title }}</option>
              <option
                v-if="field.opts"
                v-for="opt in field.opts"
                :key="`${field.title}-${opt.value}`"
                :value="opt.value"
              >
                {{ opt.desc }}
              </option>
            </select>
          </span>

          <!-- Dont using the input mask -->
          <input
            v-if="!field.isMasked"
            class="input is-medium"
            :class="{'is-danger': $v.participant[tIndex][field.token].value.$error }"
            :type="field.kind"
            @blur="$v.participant[tIndex][field.token].value.$touch()"
            v-model.trim="field.value">
        </div>

        <span class="help is-danger" v-if="!$v.participant[tIndex][field.token].value.required">
          Não pode ficar em branco.
        </span>

        <span class="help is-danger" v-if="$v.participant[tIndex][field.token].value.$params.email && !$v.participant[tIndex][field.token].value.email">
          Não é um email válido.
        </span>

        <span class="help is-danger" v-if="$v.participant[tIndex][field.token].value.$params.isCPF && !$v.participant[tIndex][field.token].value.isCPF">
          Não é um CPF válido.
        </span>

        <span class="help is-danger" v-if="$v.participant[tIndex][field.token].value.$params.isCNPJ && !$v.participant[tIndex][field.token].value.isCNPJ">
          Não é um CNPJ válido.
        </span>

        <span class="help is-danger" v-if="$v.participant[tIndex][field.token].value.$params.isCPForCNPJ && !$v.participant[tIndex][field.token].value.isCPForCNPJ">
          Não é um CPF ou CNPJ válido.
        </span>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      ticket: {
        type: Object,
        required: true
      },

      tIndex: {
        type: Number,
        reqquired: true,
      },

      $v: {
        type: Object,
        required: true
      },

      ticketTitle: {
        type: String,
        required: true
      },
    },


    computed: {
      ticketName: function() {
        return `${this.ticketTitle} n˚ ${this.tIndex+1}: ${this.ticket.fullName}`
      },

      ticketPrice: function () {
        if (this.ticket.fee_amount > 0) {
          return `${this.ticket.priceOriginalCurrency} (+ ${this.ticket.feeAmountCurrency} taxa)`
        } else {
          return this.ticket.priceOriginalCurrency
        }
      }
    },

    methods: {
      masked(field) {
        return field.masked
      }
    }
  }
</script>

<style lang="scss" scoped>
  .ticket {
    margin-bottom: 1rem;
  }

  .header {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;

    > .icon {
      fill: hsl(214, 72%, 54%);
    }

    > .info {
      margin-left: .5rem;
    }

    > .info > .price {
      font-weight: 500;
    }
  }

  .field > .label {
    font-weight: 400;
  }

  .field-body {
    flex-wrap: wrap;

    > .field {
      margin-bottom: 0.75rem;
    }
  }
</style>
