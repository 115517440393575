import * as TYPES from './types'

/* eslint-disable no-param-reassign */
export default {
  [TYPES.SET_FETCHING](state, obj) {
    state.fetching = obj.fetching
  },

  [TYPES.SET_MESSAGE](state, obj) {
    state.messages[obj.type] = obj.message
  },

  [TYPES.SET_PAYMENT_METHODS](state, obj) {
    if (obj?.CREDIT_CARD?.options) {
      const creditCards = obj.CREDIT_CARD.options
      state.paymentMethods.creditCards = Object.keys(creditCards)
        .filter((creditCard) => creditCards[creditCard].status == 'AVAILABLE')
        .map((creditCard) => creditCards[creditCard])
    }

    if (obj?.DEBIT_CARD?.options) {
      const banks = obj.DEBIT_CARD.options
      state.paymentMethods.banks =  Object.keys(banks)
        .filter((bank) => banks[bank].status == 'AVAILABLE')
        .map((bank) => banks[bank])
    }

    if (obj?.BOLETO?.options) {
      state.paymentMethods.boleto = obj.BOLETO.options.BOLETO
    }
  },

  [TYPES.SET_INSTALLMENTS](state, obj) {
    state.installments = obj
  }
}
