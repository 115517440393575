<template lang="html">
  <Loading :isLoading="isLoading" :classes="{'-loading': isLoading}">
    <div v-if="!isLoading" class="root">
      <div class="credit-card-list-wrapper">
        <div class="credit-card-list">
          <div v-for="creditCard in creditCards" class="credit-card">
            <img
              :src="getBrandImageUrl(creditCard)"
              :alt="creditCard.displayName"
              class="credit-card-image">
          </div>
        </div>
      </div>

      <div class="columns">
        <CreditCardForm
          :installments="installments"
          :cart="cart"
          :creditCard="creditCard"
          :$v="$v.creditCard">
        </CreditCardForm>

        <HolderForm
          :holder="cart.holder"
          :$v="$v.cart.holder">
        </HolderForm>

        <AddressForm
          :address="cart.address"
          :$v="$v.cart.address">
        </AddressForm>
      </div>
    </div>
  </Loading>
</template>

<script>
  import { mapActions, mapGetters, mapState } from 'vuex/dist/vuex.esm'
  import { listPaymentMethods, listInstallments } from 'src/Utils/PagSeguro'

  import Loading from 'src/Shared/Loading'
  import CreditCardForm from '../Forms/CreditCardForm'
  import AddressForm  from '../Forms/AddressForm'
  import HolderForm from '../Forms/HolderForm'


  export default {
    data() {
      return {
        isLoading: true,
      }
    },


    props: {
      pagseguroSession: {
        type: String,
        required: true
      },

      cart: {
        type: Object,
        required: true
      },

      creditCard: {
        type: Object,
        required: true
      },

      $v: {
        type: Object,
        required: true
      }
    },


    mounted() {
      PagSeguroDirectPayment.setSessionId(this.pagseguroSession)

      this.setFetching({ fetching: true })
      Promise.all([
        this.getPaymentMethods(),
        this.getInstallments()
      ]).then(() => {
        this.setFetching({ fetching: false })
        this.isLoading = false
      })
      .catch((error) => console.warn(error))
    },


    components: {
      CreditCardForm,
      AddressForm,
      HolderForm,
      Loading
    },


    computed: {
      ...mapState({
        creditCards: state => state.paymentMethods.creditCards,
        installments: state => state.installments
      }),
    },


    methods: {
      ...mapActions([
        'setFetching',
        'setMessage',
        'resetMessages',
        'setPaymentMethods',
        'setInstallments'
      ]),

      getBrandImageUrl(creditCard) {
        return `https://stc.pagseguro.uol.com.br${creditCard.images.MEDIUM.path}`
      },

      getPaymentMethods() {
        return listPaymentMethods(this.cart.priceTotal)
          .then(data => {
            this.setPaymentMethods(data)
            return Promise.resolve(data)
          })
          .catch(error => Promise.reject(error))
      },

      getInstallments() {
        return listInstallments(this.cart.priceTotal)
          .then(data => {
            this.setInstallments(data)
            return Promise.resolve(data)
          })
          .catch(error => Promise.reject(error))
      },
    }
  }
</script>

<style lang="scss" scoped>
  .root {
    min-height: 15rem;
  }

  .field > .label {
    font-weight: 400;
  }

  .title {
    color: #7F7F7F;
    font-size: 1.2rem;
    font-weight: 400;
    margin-bottom: 2rem;
  }
</style>
