import schema from './Schema'

export default class Promotion {
  constructor(data = {}) {
    Object.assign(this, schema, data)
  }

  get isCPF() {
    return this.mask == 'cpf'
  }

  get isMasked() {
    switch (this.mask) {
      case 'normal':
        return false
      default:
        return true
    }
  }

  get masked() {
    switch (this.mask) {
      case 'cpf':
        return '###.###.###-##'
      case 'cnpj':
        return '##.###.###/####-##'
      case 'cpf_cnpj':
        return ['###.###.###-##', '##.###.###/####-##']
      case 'phone':
        return ['(##) ####-####', '(##) #####-####']
      case 'date':
        return '##/##/####'
      case 'time':
        return '##:##'
      case 'datetime':
        return '##/##/#### ##:##'
      default:
        return ''
    }
  }

  get placeholder() {
    switch (this.mask) {
      case 'cpf':
        return '000.000.000-00'
      case 'cnpj':
        return '00.000.000/0000-00'
      case 'cpf_cnpj':
        return '000.000.000-00'
      case 'phone':
        return '(00) 0000-0000'
      case 'date':
        return '00/00/0000'
      case 'time':
        return '00:00'
      case 'datetime':
        return '00/00/0000 00:00'
      default:
        return ''
    }
  }

  get isSelect() {
    return this.mask === 'select';
  }
}
