<template lang="html">
  <button
    class="button is-primary is-large is-fullwidth"
    :class="classLoading"
    :disabled="isFetching"
    type="submit">
    <slot></slot>
  </button>
</template>

<script>
import { mapGetters } from 'vuex/dist/vuex.esm'

export default {
  name: 'polvo-button',

  computed: {
    ...mapGetters([
      'isFetching',
    ]),

    classLoading () {
      return {
        "is-loading": this.isFetching
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
