import { isEmpty } from 'lodash'
import { toCurrency } from 'utils/currency'
import schema from './Schema'
import FormModel from 'src/Models/Forms/Model'
import { shouldHideLot } from 'src/Utils/TicketHelper'

export default class CartTicket {
  constructor(data = {}) {
    Object.assign(this, schema, data)
    this.participant = this.setParticipant()
  }

  get fullName() {
    return `${this.sector_name} - ${this.nameByLot}`
  }

  get nameByLot() {
    let name = `${this.ticket_name}`;

    if (!shouldHideLot(this.ticket_name)) {
      name += ` (Lote ${this.ticket_lot})`;
    }

    if (this.event_location?.label?.length > 0) {
      name += ` - Mesa ${this.event_location.label}`;
    }

    return name;
  }

  get priceTotal() {
    return parseFloat(this.price_total)
  }

  get priceOriginalCurrency() {
    if (parseFloat(this.price_total) > 0) {
      return toCurrency(this.price)
    } else {
      return "Grátis"
    }
  }

  get feeAmountCurrency() {
    if (this.fee_amount > 0) {
      return toCurrency(this.fee_amount)
    } else {
      return ""
    }
  }

  get hasForms() {
    return !isEmpty(this.forms)
  }

  get hasParticipant() {
    return !isEmpty(this.participant)
  }

  setParticipant() {
    if (this.hasParticipant) {
      return this.participant.map(p => new FormModel(p))
    } else if (this.hasForms) {
      const participant = [...this.defaultData, ...this.forms]
      return participant.map(p => new FormModel(p))
    } else if (this.hasOwnProperty('participant')) {
      const participant = this.defaultData
      return participant.map(p => new FormModel(p))
    }
  }

  get defaultData() {
    return [
      {
        kind: "text",
        label: "name",
        token: "name",
        title: "Nome Completo",
        required: true,
        value: "",
        mask: "normal",
      },
      {
        kind: "text",
        label: "email",
        token: "email",
        title: "Email",
        required: true,
        value: "",
        mask: "normal",
      }
    ]
  }
}
