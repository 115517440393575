import axios from 'axios'
import interceptors from './interceptors'
import { apiUrl, csrfToken } from 'utils/config'

// allow use http client without Vue instance
export const http = axios.create({
  baseURL: apiUrl,
  headers: {
    'X-CSRF-Token': csrfToken,
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  }
})

// receive store and data by options
// https://vuejs.org/v2/guide/plugins.html
export default function install(Vue, { store }) {
  interceptors(http, store)
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/defineProperty
  Object.defineProperty(Vue.prototype, '$http', {
    get() {
      return http
    },
  })
}
