<template lang="html">
  <div class="root">
    <div v-if="!hasAvatar" class="user-avatar" :class="classes">
      <img :src="avatar_url" :alt="name" class="avatar">
    </div>

    <div v-if="hasAvatar" class="user-avatar -symbol -bordered" :class="classes">
      {{ name_symbol }}
    </div>
  </div>
</template>

<script>
  import { isEmpty } from 'lodash'

  export default {
    props: {
      avatar_url: {
        type: String,
        required: true
      },

      name: {
        type: String,
        required: true
      },

      name_symbol: {
        type: String,
        required: true
      },

      classes: {
        type: String,
        default: ""
      }
    },

    computed: {
      hasAvatar() {
        return isEmpty(this.avatar_url)
      }
    }
  }
</script>
