import { http } from 'plugins/http'
import { getData } from 'utils/get'
import * as TYPES from './types'

export default {
  // Actions Defaults
  setFetching({ commit }, obj) {
    commit(TYPES.SET_FETCHING, obj)
  },

  setMessage({ dispatch, commit }, obj) {
    commit(TYPES.SET_MESSAGE, obj)
  },

  resetMessages({ commit }) {
    commit(TYPES.SET_MESSAGE, { type: 'success', message: '' })
    commit(TYPES.SET_MESSAGE, { type: 'error', message: [] })
    commit(TYPES.SET_MESSAGE, { type: 'warning', message: '' })
    commit(TYPES.SET_MESSAGE, { type: 'validation', message: [] })
  },

  // Actions about Organizers
  createOrganizer({ commit }, payload) {
    return http.post('/spa/organizerland/organizers', payload)
      .then(getData)
      .then(data => Promise.resolve(data))
      .catch(() => Promise.reject('FAIL_IN_CREATE_ORGANIZER'))
  },

  // Actions About Users
  updateUser({ commit }, { payload }) {
    return http.patch('/spa/users', payload)
      .then(getData)
      .then(data => Promise.resolve(data))
      .catch(() => Promise.reject('FAIL_IN_UPDATE_USERS'))
  },

  // Actions About Payment Methods
  setPaymentMethods({ commit }, payload) {
    commit(TYPES.SET_PAYMENT_METHODS, payload)
  },

  setInstallments({ commit }, payload) {
    commit(TYPES.SET_INSTALLMENTS, payload)
  },

  // Actions About Contact
  createContact({ commit }, payload) {
    return http.post('/spa/contacts', payload)
      .then(getData)
      .then(data => Promise.resolve(data))
      .catch(() => Promise.reject('FAIL_IN_CREATE_CONTACT'))
  }
}
