import { http } from 'plugins/http'
import { getData } from 'utils/get'
import * as TYPES from '../../types'

export default {
  fetchEvent({ commit }, eventId) {
    return http.get(`/spa/events/${eventId}`)
      .then(getData)
      .then(data => {
        commit(TYPES.SET_CURRENT_EVENT, data)
        return Promise.resolve(data)
      })
      .catch(() => Promise.reject('FAIL_IN_FETCH_EVENT'))
  },
}
