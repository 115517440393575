import { http } from 'plugins/http'
import { getData } from 'utils/get'
import * as TYPES from '../../types'

export default {
  fetchTicketItem({ commit }, ticketItemId) {
    return http.get(`/spa/tickets/${ticketItemId}`)
      .then(getData)
      .then(data => {
        commit(TYPES.SET_CURRENT_TICKET_ITEM, data)
        return Promise.resolve(data)
      })
      .catch(() => Promise.reject('FAIL_IN_FETCH_TICKET_ITEM'))
  },

  updateTicketItem({ commit }, { ticketItemId, payload }) {
    return http.patch(`/spa/tickets/${ticketItemId}`, payload)
      .then(getData)
      .then(data => Promise.resolve(data))
      .catch(() => Promise.reject('FAIL_IN_UPDATE_TICKET_ITEM'))
  },

  updatePromoterTicketItem({ commit }, { ticketItemId, payload }) {
    return http.patch(`/spa/promoter/tickets/${ticketItemId}`, payload)
      .then(getData)
      .then(data => Promise.resolve(data))
      .catch(() => Promise.reject('FAIL_IN_UPDATE_TICKET_ITEM'))
  },
}
