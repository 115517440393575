<template lang="html">
  <div class="item-header" @click="handleChangeVisible" :class="{'-active': sector.isVisible}">
    <p class="name">{{ sector.name }}</p>
    <div class="dropdown">
      <svg class="icon is-medium" xmlns="http://www.w3.org/2000/svg" width="100px" height="100px" viewBox="0 0 100 100">
        <path d="M98 32l-7 -7c0,0 -1,0 -2,0 -2,2 -5,3 -7,3 -3,0 -6,-1 -7,-3 -4,-4 -4,-10 0,-14 0,-1 0,-2 0,-2l-7 -7c-3,-3 -6,-3 -9,0l-57 57c-3,3 -3,6 0,9l7 7c0,0 1,0 2,0 4,-4 10,-4 14,0 4,3 4,10 0,14 0,1 0,2 0,2l7 7c1,1 3,2 5,2 1,0 3,-1 4,-2l57 -57c3,-2 3,-6 0,-9zm-2 7l-40 39c0,0 0,0 0,0l-7 -7c-1,-1 -2,-1 -3,0 0,1 0,2 0,3l7 7c0,0 1,0 1,0l-15 15c-1,1 -3,1 -4,0l-6 -6c4,-5 4,-13 -1,-18 -5,-4 -13,-5 -18,-1l-5 -6c-2,-1 -2,-2 0,-3l14 -15 6 5c1,1 1,1 1,1 1,0 1,0 2,-1 0,0 0,-1 0,-2l-6 -6 40 -40c1,-1 3,-1 4,0l5 6c-4,5 -3,13 1,18 3,2 6,4 10,4 3,0 6,-1 8,-3l6 6c1,1 1,3 0,4l0 0z"/>
        <path d="M34 57c0,0 -1,0 -2,0 -1,1 -1,2 0,2l7 7c0,1 1,1 1,1 1,0 1,0 1,-1 1,0 1,-1 0,-2l-7 -7 0 0z"/>
      </svg>

      <svg :class="{'-active': sector.isVisible}" class="svg-arrow icon is-thin" xmlns="http://www.w3.org/2000/svg" width="100px" height="100px" viewBox="0 0 100 100">
        <polygon points="50,78 56,73 100,36 89,22 50,55 11,22 0,36 44,73"/>
      </svg>
    </div>
  </div>
</template>

<script>
  import { mapActions } from 'vuex/dist/vuex.esm'


  export default {
    props: {
      sector: {
        type: Object,
        required: true
      },

      index: {
        type: Number,
        required: true
      }
    },


    mounted() {
      if(this.index == 0) {
        this.handleChangeVisible()
      }
    },


    methods: {
      ...mapActions([
        'changeSectorVisible'
      ]),

      handleChangeVisible() {
        this.changeSectorVisible({ sectorId: this.sector.id })
      }
    }
  }
</script>

<style lang="scss" scoped>
  .item-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0;
    margin-bottom: 1rem;
    cursor: pointer;
    position: relative;
    // z-index: 10;
    background-color: #FFFFFF;

    > .name {
      font-size: 1.2rem;
      font-weight: 500;
    }

    > .dropdown {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    > .dropdown > .icon {
      margin-left: .5rem;
      fill: hsl(214, 72%, 54%);
    }

    &.-active {
      border-bottom: 1px dashed #c2c2c2;
    }
  }

  .svg-arrow {
    transition: all 304ms ease-in-out;
  }

  .svg-arrow.-active {
    transform: rotate(180deg);
    will-change: transform;
  }
</style>
