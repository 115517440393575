<template lang="html">
  <div class="social-share" :class="wrapperClass">
    <social-sharing :url="url"
                    :title="title"
                    :description="description"
                    :quote="quote"
                    :hashtags="hashtags"
                    :twitter-user="twitterUser"
                    inline-template>
      <div class="social-share-networks">
        <network network="facebook" class="facebook">
          <svg class="icon" xmlns="http://www.w3.org/2000/svg" width="100px" height="100px" viewBox="0 0 100 100">
            <path d="M50 0c-28,0 -50,22 -50,50 0,28 22,50 50,50 28,0 50,-22 50,-50 0,-28 -22,-50 -50,-50zm13 50l-8 0c0,14 0,31 0,31l-13 0c0,0 0,-17 0,-31l-6 0 0 -11 6 0 0 -7c0,-5 2,-13 13,-13l9 0 0 11c0,0 -6,0 -7,0 -1,0 -2,0 -2,2l0 7 9 0 -1 11z"/>
          </svg>
          <span class="text">Facebook</span>
        </network>

        <network network="sms" class="sms">
          <svg class="icon" xmlns="http://www.w3.org/2000/svg" width="100px" height="100px" viewBox="0 0 100 100">
            <path d="M79 7c10,0 19,9 19,19l0 29c0,11 -9,19 -19,19l-25 0c-3,0 -5,1 -7,3l-15 15c-1,1 -1,1 -2,1 -1,0 -2,-1 -2,-2l0 -10c0,-4 -3,-7 -7,-7 -10,0 -19,-8 -19,-19l0 -29c0,-10 9,-19 19,-19l58 0 0 0zm-8 42c4,0 8,-4 8,-8 0,-5 -4,-9 -8,-9 -5,0 -9,4 -9,9 1,4 4,8 9,8l0 0zm-21 0c5,0 8,-4 8,-8 0,-5 -3,-9 -8,-9 -5,0 -8,4 -8,9 0,4 3,8 8,8l0 0zm-21 0c5,0 8,-4 8,-8 0,-5 -3,-9 -8,-9 -4,0 -8,4 -8,9 0,4 4,8 8,8l0 0zm50 -44l-58 0c-12,0 -21,10 -21,21l0 29c0,12 9,21 21,21 3,0 5,2 5,5l0 10c0,2 2,4 4,4 1,0 2,-1 3,-1l15 -16c2,-1 4,-2 6,-2l25 0c12,0 21,-9 21,-21l0 -29c0,-11 -9,-21 -21,-21l0 0zm-8 42c-4,0 -6,-3 -6,-6 0,-4 2,-7 6,-7 3,0 6,3 6,7 0,3 -3,6 -6,6l0 0zm-21 0c-3,0 -6,-3 -6,-6 0,-4 3,-7 6,-7 3,0 6,3 6,7 0,3 -3,6 -6,6l0 0zm-21 0c-3,0 -6,-3 -6,-6 0,-4 3,-7 6,-7 4,0 6,3 6,7 0,3 -2,6 -6,6l0 0z"/>
          </svg>
          <span class="text">SMS</span>
        </network>

        <network network="twitter" class="twitter">
          <svg class="icon" xmlns="http://www.w3.org/2000/svg" width="100px" height="100px" viewBox="0 0 100 100">
            <path d="M50 0c-28,0 -50,22 -50,50 0,28 22,50 50,50 28,0 50,-22 50,-50 0,-28 -22,-50 -50,-50zm25 40c0,1 0,1 0,2 0,16 -12,35 -36,35 -7,0 -13,-2 -19,-5 1,0 2,0 3,0 6,0 11,-2 16,-5 -6,-1 -10,-4 -12,-9 1,0 2,0 2,0 1,0 3,0 4,0 -6,-2 -10,-7 -10,-13 0,0 0,0 0,0 1,1 3,2 5,2 -3,-3 -5,-6 -5,-11 0,-2 0,-4 1,-6 7,7 16,12 26,13 0,-1 0,-2 0,-3 0,-7 6,-12 13,-12 3,0 7,1 9,3 3,0 5,-1 8,-3 -1,3 -3,6 -6,7 3,0 5,-1 8,-2 -2,3 -4,5 -7,7l0 0z"/>
          </svg>
          <span class="text">Twitter</span>
        </network>

        <network network="whatsapp" class="whatsapp">
          <svg class="icon" xmlns="http://www.w3.org/2000/svg" width="100px" height="100px" viewBox="0 0 100 100">
            <path d="M100 49c0,27 -22,48 -49,48 -9,0 -17,-2 -24,-6l-27 9 9 -26c-5,-7 -7,-16 -7,-25 0,-27 22,-49 49,-49 27,0 49,22 49,49zm-49 -41c-23,0 -41,18 -41,41 0,9 3,17 7,24l-5 15 16 -5c7,4 15,7 23,7 23,0 41,-19 41,-41 0,-23 -18,-41 -41,-41zm25 52c-1,-1 -1,-1 -3,-1 -1,-1 -7,-4 -8,-4 -1,-1 -2,-1 -3,0 0,1 -3,4 -3,5 -1,1 -2,1 -3,0 -1,0 -5,-2 -10,-6 -3,-3 -6,-7 -6,-8 -1,-1 0,-2 0,-2 1,-1 1,-2 2,-2 1,-1 1,-2 1,-2 1,-1 0,-2 0,-3 0,0 -3,-6 -4,-8 -1,-3 -2,-2 -2,-2 -1,0 -2,-1 -3,-1 0,0 -2,1 -3,2 -1,1 -4,4 -4,10 0,6 4,11 5,12 0,1 8,14 20,18 13,5 13,3 15,3 2,0 7,-3 8,-5 1,-3 1,-6 1,-6z"/>
          </svg>
          <span class="text">Whatsapp</span>
        </network>
      </div>
    </social-sharing>

    <div class="fb-like"
         :data-href="url"
         data-layout="standard"
         data-action="like"
         data-size="small"
         data-show-faces="false"
         data-share="false">
    </div>
  </div>
</template>

<script>
  export default {
    name: 'social-share',


    data() {
      return {
        hashtags: 'topedindoingressos',
        twitterUser: 'topedindoingressos',
      }
    },


    props: {
      url: {
        type: String,
        required: true,
        default: ''
      },

      title: {
        type: String,
        required: true,
        default: ''
      },

      description: {
        type: String,
        required: true,
        default: ''
      },

      quote: {
        type: String,
        required: true,
        default: ''
      },

      wrapperClass: {
        type: String,
        required: false,
        default: ""
      }
    }
  }
</script>

<style lang="scss">
  .social-share {
    > .social-share-networks {
      display: flex;
      flex-direction: column;
      margin-bottom: 1rem;

      > span {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 1rem;
        margin: 0 -1rem;
        border-bottom: 1px solid #c2c2c2;
        cursor: pointer;
        transition: all 304ms ease-in-out;

        &:hover,
        &:active {
          background-color: #f6f6f6;
        }

        &.facebook:hover > .icon,
        &.facebook:active > .icon {
          fill: rgb(60, 90, 154);
        }

        &.twitter:hover > .icon,
        &.twitter:active > .icon {
          fill: rgb(85, 172, 238);
        }

        &.whatsapp:hover > .icon,
        &.whatsapp:active > .icon {
          fill: rgb(77, 194, 71);
        }
      }

      > span > .icon {
        fill: rgb(127, 127, 127);
      }

      > span > .text {
        color: rgb(127, 127, 127);
        margin-left: .5rem;
      }
    }

    &.-inline {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      > .social-share-networks {
        flex-direction: row;

        > span {
          padding: 0;
          margin: 0;
          border-bottom: unset;
          margin-right: .5rem;

          &:hover,
          &:active {
            background-color: transparent;
          }
        }

        > span > .text {
          display: none;
        }
      }
    }

    &.-email {
      > .social-share-networks {
        > span.email {
          display: none;
        }
      }
    }

    &.-facebook {
      > .social-share-networks {
        > span.facebook {
          display: none;
        }
      }
    }

    &.-sms {
      > .social-share-networks {
        > span.sms {
          display: none;
        }
      }
    }

    &.-twitter {
      > .social-share-networks {
        > span.twitter {
          display: none;
        }
      }
    }

    &.-whatsapp {
      > .social-share-networks {
        > span.whatsapp {
          display: flex;
        }
      }
    }
  }
</style>
