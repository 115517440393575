<template lang="html">
  <label class="app-dropdown-toggle" for="dropdown-desktop" @click="changeDropdown">
    <div class="app-navbar-link icon-group">
      <Avatar
        :avatar_url="avatar_url"
        :name="name"
        :name_symbol="name_symbol"
        classes="-bordered">
      </Avatar>

      <svg :class="{'-active': this.isVisible}" class="svg-arrow icon is-thin" xmlns="http://www.w3.org/2000/svg" width="100px" height="100px" viewBox="0 0 100 100">
        <polygon points="50,78 56,73 100,36 89,22 50,55 11,22 0,36 44,73"/>
      </svg>
    </div>
  </label>
</template>

<script>
  import Avatar from 'src/Shared/Avatar/Container'


  export default {
    name: 'dropdown-toggle',


    data() {
      return {
        isVisible: false
      }
    },

    props: {
      avatar_url: {
        type: String,
        required: true
      },

      name: {
        type: String,
        required: true
      },

      name_symbol: {
        type: String,
        required: true
      }
    },

    components: {
      Avatar
    },

    methods: {
      changeDropdown() {
        this.isVisible = !this.isVisible
      }
    }
  }
</script>

<style lang="scss" scoped>
  .svg-arrow {
    transition: all 304ms ease-in-out;
  }

  .svg-arrow.-active {
    transform: rotate(180deg);
    will-change: transform;
  }
</style>
