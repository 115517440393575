<template lang="html">
  <Modal
    v-if="isVisible"
    :title="modalTitle"
    :saveButtonText="modalButton"
    :isVisible="isVisible"
    :closeModal="closeModal"
    :saveButton="updateParticipant">
      <div
        v-for="field in ticket_item.participant"
        :class="{'has-error': $v.participant[field.token].value.$error }"
        class="field is-narrow">

        <label class="label is-normal">
          {{ field.title }}

          <span
            v-if="field.help"
            class="pl-tooltip-help tooltip is-tooltip-multiline"
            :data-tooltip="field.help">
            <span class="pl-tooltip-text">?</span>
          </span>
        </label>

        <div class="control">
          <!-- Using the input mask -->
          <input
            v-if="field.isMasked && !field.isSelect"
            class="input is-medium"
            :class="{'is-danger': $v.participant[field.token].value.$error }"
            :type="field.kind"
            :placeholder="field.placeholder"
            @blur="$v.participant[field.token].value.$touch()"
            v-mask="field.masked"
            v-model.trim="field.value">

          <span v-if="field.isSelect" class="select is-medium is-fullwidth">
            <select
              v-model="field.value"
            >
              <option value="" disabled selected>Selecione {{ field.title }}</option>
              <option
                v-if="field.opts"
                v-for="opt in field.opts"
                :key="`${field.title}-${opt.value}`"
                :value="opt.value"
              >
                {{ opt.desc }}
              </option>
            </select>
          </span>

          <!-- Dont using the input mask -->
          <input
            v-if="!field.isMasked"
            class="input is-medium"
            :class="{'is-danger': $v.participant[field.token].value.$error }"
            :type="field.kind"
            @blur="$v.participant[field.token].value.$touch()"
            v-model.trim="field.value">
        </div>

        <span class="help is-danger" v-if="!$v.participant[field.token].value.required">
          Não pode ficar em branco.
        </span>

        <span class="help is-danger" v-if="$v.participant[field.token].value.$params.email && !$v.participant[field.token].value.email">
          Não é um email válido.
        </span>

        <span class="help is-danger" v-if="$v.participant[field.token].value.$params.isCPF && !$v.participant[field.token].value.isCPF">
          Não é um CPF válido.
        </span>

        <span class="help is-danger" v-if="$v.participant[field.token].value.$params.isCNPJ && !$v.participant[field.token].value.isCNPJ">
          Não é um CNPJ válido.
        </span>

        <span class="help is-danger" v-if="$v.participant[field.token].value.$params.isCPForCNPJ && !$v.participant[field.token].value.isCPForCNPJ">
          Não é um CPF ou CNPJ válido.
        </span>
      </div>
  </Modal>
</template>

<script>
  import { mapActions, mapGetters, mapState } from 'vuex/dist/vuex.esm'
  import { required, email } from 'vuelidate/lib/validators'
  import isCPF from 'src/Validators/isCPF'
  import Modal from 'components/Modal'
  import { validator as pValidator } from 'src/Models/Participants/Validator'
  import FormModel from 'src/Models/Forms/Model'


  export default {
    data() {
      return {
        isVisible: false,
        modalTitle: 'Editar Participante',
        modalButton: 'Salvar',
        ticket_item: { },
      }
    },


    props: {
      ticketItemId: {
        type: String,
        required: true
      },

      data: {
        type: String,
        required: true
      },
    },


    mounted() {
      this.ticket_item = {
        participant: JSON.parse(this.data).map(p => new FormModel(p))
      }
    },


    components: {
      Modal,
    },


    validations() {
      const listValidator =
        Object.keys(this.participant).reduce((map, key) => {
          map[key] = pValidator(this.participant[key])
          return map
        }, {})

      return { participant:  {...listValidator}, }
    },


    computed: {
      participant: function() {
        return this.ticket_item.participant.reduce((map, listElement) => {
          map[listElement.token] = listElement
          return map
        }, {})
      }
    },


    methods: {
      ...mapActions([
        'setFetching',
        'setMessage',
        'resetMessages',
        'updateTicketItem',
        'updatePromoterTicketItem',
      ]),

      updateParticipant() {
        let domain = /:\/\/([^\/]+)/.exec(window.location.href)[1]
        let subdomain = domain.split('.').shift()

        if (this.$v.participant.$invalid) {
          this.$v.participant.$touch()
        } else {
          const { ticket_item } = this
          this.setFetching({ fetching: true })
          if (subdomain == 'promoter') {
            this.updatePromoterTicketItem({
              ticketItemId: this.ticketItemId,
              payload: { ticket_item: ticket_item }
            }).then((data) => {
              this.resetMessages()
              this.setFetching({ fetching: false })
              Turbolinks.visit(window.location.href)
            })
            .catch((error) => {
              console.warn('updateParticipant', error)
              this.setFetching({ fetching: false })
            })
          }
          if (subdomain != 'promoter') {
            this.updateTicketItem({
              ticketItemId: this.ticketItemId,
              payload: { ticket_item: ticket_item }
            }).then((data) => {
              this.resetMessages()
              this.setFetching({ fetching: false })
              Turbolinks.visit(window.location.href)
            })
            .catch((error) => {
              console.warn('updateParticipant', error)
              this.setFetching({ fetching: false })
            })
          }
        }
      },

      openModal() {
        this.isVisible = true
      },

      closeModal() {
        this.isVisible = false
      }
    }
  }
</script>

<style lang="scss">
  .field > .label {
    font-weight: 400;
  }
</style>
