import ClipboardJS from 'clipboard/dist/clipboard.min'

document.addEventListener('turbolinks:load', () => {

  setTimeout(() => {
    let clipboard = new ClipboardJS('.copy-to-clipboard');

    clipboard.on('success', function(e) {
      console.info('Clipboard copy:', e.action);
      alert("Copiado")

      e.clearSelection();
    });

  }, 900);
});
