<template lang="html">
  <transition name="fade">
    <div class="notification" :class="[ typeClass]" v-show="visible">
      <button @click="close()" class="delete"></button>
      <span v-html="message"></span>
    </div>
  </transition>
</template>

<script>
  export default {
    data() {
      return {
        visible: true
      }
    },


    props: {
      type: {
        type: String,
        default: 'info'
      },

      message: {
        type: String,
        required: true,
        default: '',
      }
    },


    computed: {
      // type must be success, warning, danger, info
      typeClass() {
        return `is-${this.type}`
      }
    },


    methods: {
      close() {
        this.visible = false
        this.$emit('close')
      }
    },
  }
</script>

<style lang="scss" scoped>
  .fade-enter-active {
    transition: opacity .5s
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0
  }
</style>
