<template lang="html">
    <div id="canvas-container" class="cntener">
        <canvas id="event_map" ></canvas>
    </div>
</template>

<script>
export default {
    name: 'sel-event-map',
    components: {
    },
    props: {
        ticket: {
            type: Object
        },
        event_map_json: {
            type: String
        }
    },
    data() {
        return {
            event_map: null,
            locations: [],
            location: null,
            locationObj: null, // objeto do canvas
        };
    },
    mounted() {
        this.locations.push(...this.ticket.event_locations);

        // ajusta o scroll em telas touch
        (function(){
            var defaultOnTouchStartHandler = fabric.Canvas.prototype._onTouchStart;
            fabric.util.object.extend(fabric.Canvas.prototype, {
                _onTouchStart: function(e) {
                    var target = this.findTarget(e); 

                    if (this.allowTouchScrolling && !target && !this.isDrawingMode) { 
                        return; 
                    } 

                    // otherwise call the default behavior
                    defaultOnTouchStartHandler.call(this, e); 
                }
            });
        })();

        this.event_map = new fabric.Canvas('event_map', {
            allowTouchScrolling: true,
            fireRightClick: true,
            fireMiddleClick: true,
            stopContextMenu: true,
            subTargetCheck: true,
            hoverCursor: 'default',
            selection : false,
            centeredScaling: true,
        });

        if (this.event_map_json) {
            const locations = this.locations;
            const event_map = this.event_map;
            const self = this;

            this.event_map.loadFromJSON(
                this.event_map_json,
                () => {
                    const canvas = event_map;
                    const bgimg = canvas.backgroundImage;
                    const outerCanvasContainer = document.getElementById('modal-body');

                    const containerWidth  = outerCanvasContainer.clientWidth;
                    const containerHeight = outerCanvasContainer.clientHeight;

                    const bgimgWidth = bgimg.width;
                    const bgimgHeight = bgimg.height;

                    const scaleX = containerWidth / bgimgWidth;
                    const zoomX = canvas.getZoom() * scaleX;

                    const scaleY = containerHeight / bgimgHeight;
                    const zoomY = canvas.getZoom() * scaleY;

                    console.log(containerWidth, bgimgWidth);
                    console.log(containerHeight, bgimgHeight);
                    console.log(zoomX, zoomY);

                    canvas.setDimensions({width: containerWidth, height: containerHeight});
                    canvas.setViewportTransform([zoomX, 0, 0, zoomY, 0, 0]);

                    event_map.renderAll();
                },
                (_, o) => {
                    self.lockObject(o);

                    o.set({ subTargetCheck: true });

                    o.getObjects().forEach(o2 => {
                        const ref = o2.get('location_ref');
                        const loc = locations.find(l => l.ref == ref);

                        const rect = o2.getObjects()[0];
                        const oldFill = rect.fill;

                        if (loc) {
                            self.lockObject(o2);

                            if (loc.status != 'available') {
                                rect.set({
                                    fill: 'grey'
                                });
                            } else {
                                o2.on('mousedown', () => {
                                    if (self.locationObj != null) {
                                        self.locationObj.getObjects()[0].set({
                                            fill: oldFill
                                        });
                                    }

                                    self.location = loc;
                                    self.locationObj = o2;

                                    rect.set({
                                        fill: 'yellow'
                                    });

                                    event_map.requestRenderAll();
                                });
                            }
                        } else {
                            o2.visible = false;
                        }
                    });
                }
            );
        }
    },
    methods: {
        lockObject(obj) {
            obj.setControlsVisibility({
                mt: false, 
                mb: false, 
                ml: false, 
                mr: false, 
                bl: false,
                br: false, 
                tl: false, 
                tr: false,
                mtr: false,
            });
            obj.set({
                lockMovementX: true,
                lockMovementY: true,
                lockScalingX: true,
                lockScalingY: true,
                lockUniScaling: true,
                lockRotation: true,
                selectable: false,
            });
        }
    },
    computed: {

    },
}
</script>

<style lang="scss" scoped>
.cntener {
    margin: -20px;
}
</style>