<template lang="html">
  <div ref="overlay" class="photobox-container" :class="classOverlay">
    <div class="overlay" @click="closeOverlay"></div>
    <a class="close" @click="closeOverlay">&times;</a>
    <div class="content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
  import preventScroll from 'src/Utils/preventScroll'
  import preventiOSScroll from 'src/Utils/preventiOSScroll'


  export default {
    name: 'photobox-overlay',


    mounted: function () {
      document.addEventListener("keydown", (e) => {
        if (this.isVisible && e.keyCode == 27) {
          this.closeOverlay()
        }
      })
    },


    watch: {
      isVisible: function(val) {
        preventiOSScroll(this.$refs.overlay)
        preventScroll(val, document.documentElement)
      }
    },


    props: {
      isVisible: {
        type: Boolean,
        required: true,
        default: false
      },

      closeOverlay: {
        type: Function,
        required: true
      },
    },


    computed: {
      classOverlay: function() {
        return {
          "-visible": this.isVisible
        }
      },
    }
  }
</script>

<style lang="scss" scoped>
  .photobox-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 50;
    visibility: hidden;
    opacity: 0;
    transition: opacity 304ms ease-in-out;
    -webkit-overflow-scrolling: touch;

    &.-visible {
      visibility: visible;
      opacity: 1;
    }
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 50;
    background-color: rgba(0, 0, 0, 0.8);
  }

  .close {
    position: fixed;
    top: 0;
    right: 1rem;
    color: hsl(0, 0%, 76%);
    z-index: 100;
    font-size: 2rem;
    transition: color 304ms ease-in-out;

    &:hover {
      color: #FFFFFF;
    }
  }

  .content {
    display: flex;
    justify-content: center;
    position: relative;
    top: 10%;
    left: 10%;
    right: 10%;
    bottom: 10%;
    max-width: 80%;
    max-height: 80%;
    background: red;

    > img {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      max-width: 80%;
      max-height: 80%;
      z-index: 100;
      object-fit: scale-down;
      will-change: transform;
    }
  }
</style>
