import schema from './Schema'
import { isEmpty } from 'lodash'

export default class Promotion {
  constructor(data = {}) {
    Object.assign(this, schema, data)
    this.limitless = this.setLimitless()
    this.expiresless = this.setExpiresless()
  }

  setLimitless() {
    if (this.amount > 0) {
      return  false
    } else {
      return true
    }
  }

  setExpiresless() {
    if (isEmpty(this.expires_in)) {
      return  true
    } else {
      return false
    }
  }
}
