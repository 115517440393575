<template lang="html">
  <iframe
    :id="mapName"
    class="google-map"
    loading="lazy"
    referrerpolicy="no-referrer-when-downgrade"
    :src="mapsUrl">
  </iframe>
</template>

<script>
  export default {
    name: 'google-map',

    data() {
      return {
        mapName: this.name + '-map',
      }
    },

    props: {
      name: {
        type: String,
        required: true
      },

      address: {
        type: String,
        required: true
      }
    },


    computed: {
      mapsUrl() {
        return encodeURI(
          `https://www.google.com/maps/embed/v1/place?key=AIzaSyDJD4Jq0HbUSckNJH39RrK8vpmv90hADEg&q=${this.address}`
        );
      }
    }
  }
</script>

<style lang="scss" scoped>
  .google-map {
    width: 100%;
    height: 300px;
    margin: 0 auto;
    background: gray;
    border: 0;
  }
</style>
