<template lang="html">
  <label class="app-dropdown-toggle" for="dropdown-mobile" @click="changeDropdown">
    <div class="app-navbar-link icon-group">
      <svg width="100px" height="100px" class="icon is-medium" viewBox="0 0 260 260" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M154.94 19.2501C105.621 19.2501 58.3268 46.2759 32.9393 89.5448C8.86021 130.577 17.8846 163.58 34.3574 180.785C42.9281 189.736 53.7463 196.714 64.2106 203.464C67.0913 205.32 69.9675 207.177 72.8038 209.072C84.9035 217.16 99.4473 226.275 115.59 232.551C130.21 238.229 158.708 246.167 185.339 235.473C189.334 233.868 193.234 231.963 196.932 229.807C222.615 214.851 238.428 188.38 238.203 160.726C238.129 151.802 238.341 142.73 238.549 133.956C238.786 123.872 239.032 113.448 238.839 103.256C238.43 81.5945 235.769 54.6964 218.665 38.8252C206.267 27.319 187.268 20.799 162.196 19.4477C159.776 19.317 157.352 19.2501 154.94 19.2501V19.2501ZM156.648 246C139.195 246 123.469 241.244 113.689 237.444C97.0714 230.986 82.2203 221.684 69.8856 213.437C67.0733 211.558 64.2204 209.717 61.3645 207.877C50.647 200.961 39.5629 193.814 30.5634 184.416C18.6853 172.009 12.6182 155.709 13.0186 137.279C13.3747 120.866 18.6973 103.44 28.409 86.8894C56.0238 39.825 108.638 11.2946 162.48 14.2031C188.809 15.6235 208.915 22.6138 222.237 34.9748C240.79 52.1936 243.66 80.458 244.088 103.156C244.284 113.46 244.037 123.942 243.797 134.079C243.592 142.813 243.381 151.843 243.451 160.684C243.695 190.215 226.882 218.442 199.573 234.345C195.658 236.626 191.526 238.645 187.296 240.345C177.039 244.463 166.563 246 156.648 246" fill="#1B6DBF"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M237.086 175.418C214.666 232.653 136.878 240.657 83.2886 219.665C29.7027 198.674 20.6062 156.632 43.0274 99.3974C65.4479 42.1623 123.703 23.8071 180.654 33.7742C244.606 44.9686 259.507 118.183 237.086 175.418Z" fill="#FFE000"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M247.639 126.586C247.635 130.41 247.325 134.278 246.7 138.159C243.174 160.004 227.188 178.295 209.798 190.942C197.324 200.014 183.44 206.882 169.391 213.14C155.548 219.305 141.994 226.573 127.058 229.845C94.2292 237.043 65.0318 216.718 48.4079 189.616C23.5191 149.047 25.1281 97.491 52.9404 58.8545C64.038 43.4423 78.69 29.0674 97.4599 23.8634C119.279 17.8129 142.931 27.3518 162.225 37.222C178.156 45.3728 193.456 54.7186 209.307 63.0309C233.138 75.5302 247.675 100.041 247.639 126.586" fill="#00C956"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M55.8708 55.9806C63.8428 47.1297 74.3695 40.4091 86.1949 36.3882C95.2133 33.3227 104.74 31.7618 114.254 31.5688C129.769 31.2548 145.971 34.8994 161.036 38.372C189.592 44.9514 223.442 57.4777 234.128 87.5705C237.742 97.7554 238.686 108.746 238.044 119.534C235.409 163.889 203.045 207.566 160.297 220.881C143.877 225.992 125.775 226.28 109.618 220.392C86.8146 212.087 70.0301 192.339 60.3927 170.611C49.1908 145.363 37.7792 117.992 40.7222 89.7593C42.0901 76.6254 47.6073 65.1575 55.8708 55.9806" fill="#1B6DBF"/>
        <path d="M122.914 139.436C121.782 139.587 120.579 139.665 119.344 139.665C115.458 139.665 114.072 138.778 113.591 138.252C112.601 137.161 112.82 134.898 113.179 133.2L118.902 106.565H130.699L134.2 90.0748C134.382 89.2132 135.133 88.604 136.005 88.58H122.677L126.505 70.6364C126.64 70.0054 126.445 69.3497 125.986 68.8967C125.628 68.5369 125.145 68.3439 124.65 68.3439C124.514 68.3439 124.379 68.3596 124.242 68.3897L106.842 72.2138C106.114 72.3746 105.547 72.9462 105.394 73.6763L102.256 88.58H90.84C89.9409 88.58 89.1664 89.2095 88.9816 90.0883L86.0206 104.28C85.9034 104.84 86.0446 105.421 86.4067 105.863C86.765 106.308 87.3058 106.565 87.8789 106.565H98.4943L92.4355 135.135C90.997 142.146 92.0832 147.706 95.6662 151.661C99.1884 155.553 104.873 157.528 112.557 157.528C114.516 157.528 116.672 157.375 118.972 157.079C119.332 157.034 119.662 156.985 120.002 156.937L123.747 139.303C123.469 139.348 123.18 139.4 122.914 139.436" fill="white"/>
        <path d="M174.416 135.84C170.847 139.447 166.587 141.201 161.391 141.201C158.464 141.201 155.7 140.616 153.168 139.457C150.975 138.46 149.199 137.128 147.879 135.493L152.982 111.704C154.57 110.482 156.323 109.525 158.203 108.853C160.29 108.107 162.524 107.728 164.845 107.728C169.204 107.728 172.696 109.137 175.516 112.03C178.353 114.943 179.73 118.444 179.73 122.737C179.73 127.939 177.991 132.227 174.416 135.84V135.84ZM197.329 108.761C195.767 104.898 193.622 101.521 190.957 98.7228C188.285 95.9188 185.024 93.6781 181.265 92.0586C177.502 90.4413 173.43 89.6203 169.164 89.6203C166.379 89.6203 163.612 89.9967 160.94 90.7388C159.516 91.1377 158.114 91.6319 156.74 92.2238C156.708 91.8415 156.561 91.4734 156.311 91.1737C155.952 90.7373 155.417 90.4857 154.849 90.4857H138.439C138.421 90.4857 138.407 90.4939 138.389 90.4939C137.515 90.518 136.766 91.1272 136.582 91.988L133.08 108.477L126.13 141.217L122.385 158.851L117.454 182.068C117.335 182.627 117.477 183.211 117.837 183.655C118.196 184.1 118.738 184.359 119.311 184.359H135.846C136.739 184.359 137.512 183.736 137.702 182.863L143.955 153.944C146.004 155.435 148.282 156.636 150.75 157.531C154.236 158.794 158.023 159.432 162.008 159.432C167.182 159.432 172.129 158.424 176.705 156.436C181.273 154.456 185.296 151.7 188.661 148.246C192.015 144.81 194.717 140.737 196.696 136.136C198.681 131.514 199.689 126.507 199.689 121.257C199.689 116.837 198.894 112.633 197.329 108.761" fill="white"/>
      </svg>

      <svg :class="{'-active': this.isVisible}" class="svg-arrow icon is-thin" xmlns="http://www.w3.org/2000/svg" width="100px" height="100px" viewBox="0 0 100 100">
        <polygon points="50,78 56,73 100,36 89,22 50,55 11,22 0,36 44,73"/>
      </svg>
    </div>
  </label>
</template>

<script>
  import preventScroll from 'src/Utils/preventScroll'
  import preventiOSScroll from 'src/Utils/preventiOSScroll'


  export default {
    name: 'dropdown-toggle',


    data() {
      return {
        isVisible: false
      }
    },


    watch: {
      isVisible: function(val) {
        preventiOSScroll(document.getElementById('dropdown-items'))
        preventScroll(val, document.documentElement)
      }
    },


    methods: {
      changeDropdown() {
        this.isVisible = !this.isVisible
      }
    }
  }
</script>

<style lang="scss" scoped>
  .svg-arrow {
    transition: all 304ms ease-in-out;
  }

  .svg-arrow.-active {
    transform: rotate(180deg);
    will-change: transform;
  }
</style>
