<template lang="html">
  <div class="promotion-container">
    <div v-if="!promotionForm.isValid" class="promotion-form">
      <a v-if="!promotionForm.isApplied" @click="apply">Aplicar código promocional</a>

      <form @submit.prevent="handbleFetchPromotion" v-if="promotionForm.isApplied">
        <div class="field has-addons">
          <div class="control">
            <input type="text" class="input is-medium" v-model="promotionForm.code">
          </div>

          <div class="control">
            <button
              type="submit"
              class="button is-primary is-medium"
              :class="{'is-loading': isFetching}"
              @click="handbleFetchPromotion">
              APLICAR
            </button>
          </div>
        </div>
      </form>
    </div>

    <div v-if="promotionForm.isValid" class="promotion-valid">
      Código aplicado: <span class="code">{{ promotion.code }}</span>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex/dist/vuex.esm'
  import { isEmpty } from 'lodash'

  export default {
    data() {
      return {
        promotionForm: {
          isApplied: false,
          code: '',
          isValid: false,
        }
      }
    },


    props: {
      promotionCode: {
        type: String,
        default: ""
      }
    },


    computed: {
      ...mapGetters({
        cart: 'currentCart',
        isFetching: 'isFetching',
        promotion: 'currentPromotion',
      }),
    },


    mounted() {
      if (!isEmpty(this.promotionCode)) {
        this.promotionForm.isApplied = true
        this.promotionForm.code = this.promotionCode
        this.handbleFetchPromotion()
      }
    },


    methods: {
      ...mapActions([
        'setFetching',
        'setMessage',
        'fetchPromotion',
      ]),

      handbleFetchPromotion() {
        this.setFetching({ fetching: true })
        this.fetchPromotion({
          eventId: this.cart.event_id,
          promotionCode: this.promotionForm.code,
        }).then((data) => {
          this.promotionForm.isValid = true
          this.setFetching({ fetching: false })
        })
        .catch((error) => {
          this.setMessage({
            type: 'warning',
            message: `Ops! Esse código pode estar inválido ou expirado.`
          })
          this.setFetching({ fetching: false })
        })
      },

      apply() {
        this.promotionForm.isApplied = true
      }
    }
  }
</script>

<style lang="scss" scoped>
  .promotion-container {
    margin-right: 2rem;

    @media screen and (max-width: 768px) {
      & {
        margin-right: 0;
      }
    }
  }

  .promotion-valid > .code {
    color: hsl(145, 63%, 49%);
    font-weight: 600;
  }
</style>
