<template lang="html">
  <div class="column">
    <h3 class="title">Endereço</h3>

    <div class="field" :class="{'has-error': $v.street.$error }">
      <label class="label is-normal">Av/Rua</label>
      <div class="control">
        <input
          class="input is-medium"
          :class="{'is-danger': $v.street.$error }"
          type="text"
          @blur="$v.street.$touch()"
          v-model="address.street">
      </div>

      <span class="help is-danger" v-if="!$v.street.required">
        Não pode ficar em branco.
      </span>
    </div>

    <div class="field" :class="{'has-error': $v.number.$error }">
      <label class="label is-normal">Número</label>
      <div class="control">
        <input
          class="input is-medium"
          :class="{'is-danger': $v.number.$error }"
          type="text"
          @blur="$v.number.$touch()"
          v-model="address.number">
      </div>

      <span class="help is-danger" v-if="!$v.number.required">
        Não pode ficar em branco.
      </span>
    </div>

    <div class="field">
      <label class="label is-normal">Complemento</label>
      <div class="control">
        <input
          class="input is-medium"
          type="text"
          v-model="address.complement">
      </div>
    </div>

    <div class="field" :class="{'has-error': $v.neighborhood.$error }">
      <label class="label is-normal">Bairro</label>
      <div class="control">
        <input
          class="input is-medium"
          :class="{'is-danger': $v.neighborhood.$error }"
          type="text"
          @blur="$v.neighborhood.$touch()"
          v-model="address.neighborhood">
      </div>

      <span class="help is-danger" v-if="!$v.neighborhood.required">
        Não pode ficar em branco.
      </span>
    </div>

    <div class="field" :class="{'has-error': $v.zip_code.$error }">
      <label class="label is-normal">CEP</label>
      <div class="control">
        <input
          class="input is-medium"
          :class="{'is-danger': $v.zip_code.$error }"
          type="text"
          v-mask="'#####-###'"
          @blur="$v.zip_code.$touch()"
          v-model="address.zip_code">
      </div>

      <span class="help is-danger" v-if="!$v.zip_code.required">
        Não pode ficar em branco.
      </span>
    </div>

    <div class="field-body">
      <div class="field" :class="{'has-error': $v.state.$error }">
        <label class="label is-normal">Estado</label>
        <div
          class="select is-medium is-fullwidth"
          :class="{'is-danger': $v.state.$error }"
          @change="$v.state.$touch()">
          <select v-model="address.state">
            <option v-for="state in states" :value="state">
              {{ state }}
            </option>
          </select>
        </div>

        <span class="help is-danger" v-if="!$v.state.required">
          Não pode ficar em branco.
        </span>
      </div>

      <div class="field" :class="{'has-error': $v.city.$error }">
        <label class="label is-normal">Cidade</label>
        <div class="control">
          <input
            class="input is-medium"
            :class="{'is-danger': $v.city.$error }"
            type="text"
            @blur="$v.city.$touch()"
            v-model="address.city">
        </div>

        <span class="help is-danger" v-if="!$v.city.required">
          Não pode ficar em branco.
        </span>
      </div>
    </div>

  </div>
</template>

<script>
  export default {
    data() {
      return {
        states: [
          "AC", "AL", "AP", "AM", "BA", "CE",
          "DF", "ES", "GO", "MA", "MT", "MS",
          "MG", "PA", "PB", "PR", "PE", "PI",
          "RJ", "RN", "RS", "RO", "RR", "SC",
          "SP", "SE", "TO"
        ]
      }
    },


    props: {
      address: {
        type: Object,
        required: true
      },

      $v: {
        type: Object,
        required: true
      }
    }
  }
</script>

<style lang="scss" scoped>
  .field > .label {
    font-weight: 400;
  }

  .title {
    color: #7F7F7F;
    font-size: 1.2rem;
    font-weight: 400;
    margin-bottom: 2rem;
  }
</style>
