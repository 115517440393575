export default {
  currentCart: state => state.current,

  amountByTicket: state => ticket => {
    const amount = state.current.cart_tickets_attributes.filter((cartTicket) => {
      if (cartTicket.ticket_id == ticket.id) { return cartTicket }
    })

    return amount.length
  },
}
