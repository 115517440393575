<template lang="html">
  <Form
    :cities="JSON.parse(cities)"
    :params="JSON.parse(params)">
  </Form>
</template>

<script>
  import Form    from './Search/Form'


  export default {
    props: {
      cities: {
        type: String,
        required: true
      },

      params: {
        type: String,
        required: true
      }
    },

    components: {
      Form
    },
  }
</script>

<style lang="scss">
</style>
