<template lang="html">
  <div class="shopping-cart">
    <a @click="openOverlay" class="event-cart-shopping-cart-button" :class="className">
      <svg class="icon is-medium" xmlns="http://www.w3.org/2000/svg" width="100px" height="100px" viewBox="0 0 100 100">
        <path d="M98 32l-7 -7c0,0 -1,0 -2,0 -2,2 -5,3 -7,3 -3,0 -6,-1 -7,-3 -4,-4 -4,-10 0,-14 0,-1 0,-2 0,-2l-7 -7c-3,-3 -6,-3 -9,0l-57 57c-3,3 -3,6 0,9l7 7c0,0 1,0 2,0 4,-4 10,-4 14,0 4,3 4,10 0,14 0,1 0,2 0,2l7 7c1,1 3,2 5,2 1,0 3,-1 4,-2l57 -57c3,-2 3,-6 0,-9zm-2 7l-40 39c0,0 0,0 0,0l-7 -7c-1,-1 -2,-1 -3,0 0,1 0,2 0,3l7 7c0,0 1,0 1,0l-15 15c-1,1 -3,1 -4,0l-6 -6c4,-5 4,-13 -1,-18 -5,-4 -13,-5 -18,-1l-5 -6c-2,-1 -2,-2 0,-3l14 -15 6 5c1,1 1,1 1,1 1,0 1,0 2,-1 0,0 0,-1 0,-2l-6 -6 40 -40c1,-1 3,-1 4,0l5 6c-4,5 -3,13 1,18 3,2 6,4 10,4 3,0 6,-1 8,-3l6 6c1,1 1,3 0,4l0 0z"/>
        <path d="M34 57c0,0 -1,0 -2,0 -1,1 -1,2 0,2l7 7c0,1 1,1 1,1 1,0 1,0 1,-1 1,0 1,-1 0,-2l-7 -7 0 0z"/>
      </svg>
      <span class="text">
        {{ textButton }}
      </span>
    </a>

    <Overlay :isVisible="isVisible" :closeOverlay="closeOverlay" :title="textTitle">
      <ShoppingCart
        v-if="isVisible"
        :event_id="event_id"
        :tax="tax"
        :customer_fee="customer_fee"
        :promotion_code="promotion_code"
        :textBuyer="textBuyer">
      </ShoppingCart>
    </Overlay>
  </div>
</template>

<script>
  import Overlay from 'src/Shared/Overlay'
  import ShoppingCart from 'src/Application/ShoppingCart/ShoppingCart'


  export default {
    name: 'shopping-cart-button',

    data() {
      return {
        isVisible: false,
      }
    },

    props: {
      event_id: {
        type: String,
        required: true
      },

      tax: {
        type: String,
        required: true
      },

      customer_fee: {
        type: String,
        required: true
      },

      className: {
        type: String,
        required: false,
        default: ''
      },

      promotion_code: {
        type: String,
        default: ""
      },

      textButton: {
        type: String,
        required: true
      },

      textTitle: {
        type: String,
        required: true
      },

      textBuyer: {
        type: String,
        required: true
      },
    },


    components: {
      Overlay,
      ShoppingCart
    },


    methods: {
      openOverlay() {
        this.isVisible = true
      },

      closeOverlay() {
        this.isVisible = false
      },
    }
  }
</script>
