<template lang="html">
  <div class="canvas-container">
    <canvas id="certificate"></canvas>
  </div>
</template>

<script>
  import { isEmpty } from 'lodash'
  import Liquid from 'liquidjs'
  import jsPDF from 'jspdf'


  const liquid = new Liquid()


  export default {
    data() {
      return {
        canvas: {},

        options: {
          width: 842,
          height: 595,
        }
      }
    },


    props: {
      certificate: {
        type: Object,
        required: true,
      },

      tags: {
        type: Object,
        required: true,
      }
    },


    mounted() {
      this.canvas = new fabric.Canvas('certificate', this.options)
    },


    methods: {
      initCanvas() {
        Promise.all(
          this.certificate.objects.map(obj => {
            if (obj.type == 'textbox') {
              return this.setText(obj).then(data => Promise.resolve(data))
            } else {
              return Promise.resolve(obj)
            }
          })
        ).then(data => {
          const objects = { objects: data }
          this.canvas.loadFromJSON(JSON.stringify(objects))
          this.canvas.selection = false
          this.canvas.forEachObject(o => o.selectable = false)
          this.setBackgroundImage(this.canvas, this.certificate.picture_url)
        })
      },

      savePDF() {
        const imgData = this.canvas.toDataURL({
          format: 'png',
          multiplier: 2,
          enableRetinaScaling: true,
        })

        const pdf = new jsPDF({
          orientation: 'landscape',
          format: 'a4',
        })

        const width = pdf.internal.pageSize.width
        const height = pdf.internal.pageSize.height

        pdf.addImage(imgData, 'png', 0, 0, width, height, '', 'FAST')
        pdf.save("certificado.pdf")
      },

      setBackgroundImage(canvas, picture) {
        canvas.setBackgroundImage(picture,
          canvas.renderAll.bind(canvas), {
            width: canvas.width,
            height: canvas.height,
            originX: 'left',
            originY: 'top',
            crossOrigin: 'anonymous',
        })
      },

      setText(object) {
        return liquid
          .parseAndRender(object.text, this.tags)
          .then(data => {
            object.text = data
            return Promise.resolve(object)
          })
      },
    }
  }
</script>

<style lang="scss" scoped>
  .canvas-container {
    margin: 1rem 0;
  }
</style>
