import Vue from 'vue/dist/vue.esm'
import Vuex from 'vuex/dist/vuex.esm'

import state from './state'
import mutations from './mutations'
import actions from './actions'
import * as getters from './getters'

import sectors from './Modules/Sectors'
import promotions from './Modules/Promotions'
import carts from './Modules/Carts'
import certificates from './Modules/Certificates'
import ticket_items from './Modules/TicketItems'
import events from './Modules/Events'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    sectors,
    promotions,
    carts,
    certificates,
    ticket_items,
    events,
  },
  state,
  mutations,
  actions,
  getters,
  strict: process.env.NODE_ENV !== 'production',
})
