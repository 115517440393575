<template lang="html">
  <div class="button-social-shared" :class="className">
    <a @click="openOverlay" class="social-share-action">
      <svg class="icon" xmlns="http://www.w3.org/2000/svg" width="100px" height="100px" viewBox="0 0 100 100">
        <path d="M74 0c-8,0 -15,7 -15,16 0,2 0,4 1,5l-23 18c-3,-3 -7,-5 -11,-5 -9,0 -16,7 -16,16 0,9 7,16 16,16 4,0 8,-2 11,-5l23 18c-1,1 -1,3 -1,5 0,9 7,16 15,16 9,0 16,-7 16,-16 0,-9 -7,-16 -16,-16 -5,0 -11,4 -13,8l-23 -17c2,-2 3,-5 3,-9 0,-3 -1,-7 -3,-9l23 -17c2,4 8,8 13,8 9,0 16,-7 16,-16 0,-9 -7,-16 -16,-16l0 0 0 0zm0 2c8,0 14,6 14,14 0,7 -6,13 -14,13 -7,0 -13,-6 -13,-13 0,-8 6,-14 13,-14zm-48 35c7,0 13,6 13,13 0,7 -6,13 -13,13 -8,0 -14,-6 -14,-13 0,-7 6,-13 14,-13zm48 34c8,0 14,6 14,13 0,8 -6,14 -14,14 -7,0 -13,-6 -13,-14 0,-7 6,-13 13,-13z"/>
      </svg>
      <span class="text">COMPARTILHAR COM AMIGOS</span>
    </a>

    <Overlay :isVisible="isVisible" :closeOverlay="closeOverlay" title="Compartilhar">
      <SocialShare :url="url" :title="title" :description="description" :quote="quote">
      </SocialShare>
    </Overlay>
  </div>
</template>

<script>
  import Overlay from '../Overlay'
  import SocialShare from '../SocialShare'


  export default {
    name: 'button-social-share',


    data() {
      return {
        isVisible: false,
      }
    },


    props: {
      url: {
        type: String,
        required: true,
        default: ''
      },

      title: {
        type: String,
        required: true,
        default: ''
      },

      description: {
        type: String,
        required: true,
        default: ''
      },

      quote: {
        type: String,
        required: true,
        default: ''
      },

      className: {
        type: String,
        required: false,
        default: ''
      }
    },


    components: {
      Overlay,
      SocialShare
    },


    methods: {
      openOverlay() {
        this.isVisible = true
      },

      closeOverlay() {
        this.isVisible = false
      },
    }
  }
</script>

<style lang="scss" scoped>
  .button-social-shared {
    .social-share-action {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 1rem;
      line-height: 2;

      > .text {
        margin-left: .5rem;
        color: rgb(127, 127, 127);
      }
    }
  }
</style>
