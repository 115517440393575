<template lang="html">
  <div class="root">
    <Canvas
      ref="canvas"
      :certificate="certificate"
      :tags="tags">
    </Canvas>

    <a title="Baixar Certificado" class="button is-primary is-large" @click.prevent="savePDF">
      <span>Baixar</span>
    </a>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import { isEmpty } from 'lodash'
  import Moment from 'moment'
  import Liquid from 'liquidjs'
  import jsPDF from 'jspdf'
  import Canvas from './Canvas'

  export default {
    data() {
      return {
        certificate: {},
        tags: {
          participante: "",
          tipo_de_ingresso: "",
          organizador: "",
          data_de_inicio: "",
          data_de_termino: "",
          evento: "",
        },
      }
    },


    props: {
      ticket_item_id: {
        type: String,
        required: true
      },

      event_id: {
        type: String,
        required: true
      },
    },

    computed: {
      ...mapGetters([
        'currentTicketItem',
      ]),
    },


    components: {
      Canvas,
    },


    mounted() {
      Promise.all([
        this.handleFetchCertificate(),
        this.handleFetchTicketItem(),
        this.handleFetchEvent(),
      ]).then(() => {
        this.$refs.canvas.initCanvas()
      })
      .catch(error => Promise.reject(error))
    },


    methods: {
      ...mapActions([
        'fetchCertificate',
        'fetchTicketItem',
        'fetchEvent'
      ]),

      handleFetchCertificate() {
        return this.fetchCertificate({
          ticketItemId: this.ticket_item_id
        }).then(data => {
          this.certificate = data
          return Promise.resolve(data)
        })
        .catch(error => Promise.reject(error))
      },

      handleFetchTicketItem() {
        return this.fetchTicketItem(this.ticket_item_id)
          .then(data => {
            const participant = data.participant.find(p => p.label == 'name')
            this.tags.participante = participant.value
            this.tags.tipo_de_ingresso = this.currentTicketItem.fullName
            return Promise.resolve(data)
          })
          .catch(error => Promise.reject(error))
      },

      handleFetchEvent() {
        return this.fetchEvent(this.event_id)
          .then(data => {
            this.tags.evento = data.name
            this.tags.data_de_inicio = Moment(data.starts_at).format('DD/MM/YYYY')
            this.tags.data_de_termino = Moment(data.ends_in).format('DD/MM/YYYY')
            this.tags.organizador = data.organizer.name
            return Promise.resolve(data)
          })
          .catch(error => Promise.reject(error))
      },

      savePDF() {
        this.$refs.canvas.savePDF()
      },
    }
  }
</script>

<style lang="scss" scoped>
  .root {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .button {
    display: flex !important;
    justify-content: center;
    align-items: center;
  }
</style>
