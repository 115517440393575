import { required, requiredIf, email } from 'vuelidate/lib/validators'
import isCPF from 'src/Validators/isCPF'
import isCNPJ from 'src/Validators/isCNPJ'
import isCPForCNPJ from 'src/Validators/isCPForCNPJ'


export function validator(participant) {
  switch (participant.label) {
    case "email":
      return {
        value: {
          required: requiredIf(obj => obj.required),
          email,
        }
      }
      break;
    case "cpf":
      return {
        value: {
          required: requiredIf(obj => obj.required),
          isCPF,
        }
      }
      break;
    case "cnpj":
      return {
        value: {
          required: requiredIf(obj => obj.required),
          isCNPJ,
        }
      }
      break;
    case "cpf_cnpj":
      return {
        value: {
          required: requiredIf(obj => obj.required),
          isCPForCNPJ,
        }
      }
      break;
    default:
      return {
        value: {
          required: requiredIf(obj => obj.required),
        }
      }
  }
}
