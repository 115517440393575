<template lang="html">
  <div class="create-organizer-form">
    <h1 class="title">Quem vai organizar os eventos?</h1>

    <form class="form" @submit.prevent="handleSubmit">
      <div class="control is-grouped">
        <div class="field">
          <div class="control">
            <input
            class="input is-large"
            :class="{'is-danger': $v.organizer.name.$error }"
            type="text"
            autofocus="true"
            placeholder="Escreva o nome da produtora"
            name="organizer[name]"
            :disabled="isDisabled"
            v-model="organizer.name"
            @keydown.enter="handleSubmit">
          </div>
        </div>

        <polvo-button>Criar</polvo-button>
      </div>
    </form>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex/dist/vuex.esm'
  import { required } from 'vuelidate/lib/validators'
  import PolvoButton from 'components/PolvoButton'

  export default {
    name: 'create-organizer-form',

    props: ['closeOverlay'],

    data() {
      return {
        organizer: {
          name: ''
        }
      }
    },

    components: {
      PolvoButton
    },

    validations: {
      organizer: {
        name: { required },
      }
    },

    computed: {
      ...mapGetters([
        'isDisabled',
      ]),
    },

    methods: {
      ...mapActions([
        'setFetching',
        'createOrganizer'
      ]),

      handleSubmit(e) {
        if (this.$v.organizer.$invalid) {
          this.$v.organizer.$touch()
          e.preventDefault()
        } else if (e.keyCode === 13 && !e.shiftKey) {
          e.preventDefault()
          this.submit()
        } else {
          this.submit()
        }
      },

      submit() {
        this.setFetching({ fetching: true })
        const { organizer } = this
        this.createOrganizer({ organizer })
        .then(data => {
          if(this.closeOverlay) this.closeOverlay()
          Turbolinks.visit(`/?organizer_id=${data.id}`)
          this.setFetching({ fetching: false })
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  .create-organizer-form {
    width: 49rem;
    margin: 0 auto;
    padding-top: 10%;

    > .form {
      width: 70%;
      margin: 0 auto;
    }

    > .title {
      font-weight: 300;
      font-size: 3rem;
      text-align: center;
      margin-bottom: 5rem;
    }
  }
</style>
