export default {
  messages: {
    success: '',
    error: [],
    warning: '',
    validation: [],
  },
  paymentMethods: {
    boleto: {},
    creditCards: [],
    banks: []
  },
  installments: {},
  fetching: false,
}
