<template lang="html">
  <ul class="footer-link-wrapper" :class="direction">
    <li>
      <a class="footer-link-item" href="/p/sobre" data-turbolinks="false">
        Sobre o Topedindo Ingressos
      </a>
    </li>
    <li>
      <a class="footer-link-item" href="/p/como-funciona" data-turbolinks="false">
        Organizadores de Eventos
      </a>
    </li>
    <li>
      <a class="footer-link-item" href="https://ajuda.topedindoingressos.com.br" target="_blank">
        Perguntas?
      </a>
    </li>
    <li>
      <a target="_blank" href="https://www.facebook.com/topedindoingressos" class="footer-link-item">
        Facebook
      </a>
    </li>
    <li>
      <a target="_blank" href="https://instagram.com/topedindoingressos" class="footer-link-item">
        Instagram
      </a>
    </li>
  </ul>
</template>

<script>
  export default {
    props: {
      direction: {
        type: String,
        default: ''
      }
    },
  }
</script>

<style lang="scss">
</style>
