<template lang="html">
  <div class="root">
    <a class="app-navbar-link" @click="openOverlay">
      <svg xmlns="http://www.w3.org/2000/svg" width="100px" height="100px" viewBox="0 0 100 100" class="icon is-medium">
        <path d="M17 16c-1,1 -1,3 0,4 1,1 2,1 3,0 11,-12 30,-12 41,0 0,0 1,0 1,0 1,0 2,0 2,0 1,-1 1,-3 0,-4 -13,-13 -34,-13 -47,0z"></path>
        <path d="M98 88l-13 -13c-2,-2 -5,-3 -8,-2 0,0 0,0 0,0l-7 -6c15,-16 14,-40 -1,-56 -16,-15 -41,-15 -57,0 -16,16 -16,42 0,57 8,8 18,12 28,12 10,0 19,-3 27,-10l6 6c-1,3 -1,7 2,8l13 14c1,1 3,2 5,2 1,0 3,-1 5,-2 3,-3 3,-7 0,-10l0 0 0 0zm-83 -23c-14,-14 -14,-37 0,-50 7,-7 16,-11 25,-11 10,0 18,4 25,11 15,13 15,36 1,50 -14,14 -37,14 -51,0l0 0zm80 30c-1,1 -3,1 -4,0l-13 -13c-1,-1 -1,-3 0,-4 1,0 1,0 2,0 0,0 1,0 1,0l14 13c0,1 0,3 0,4l0 0z"></path>
      </svg>
    </a>

    <Overlay :isVisible="isVisible" :closeOverlay="closeOverlay" :title="title">
      <Form
        v-if="isVisible"
        :cities="JSON.parse(cities)"
        :params="JSON.parse(params)">
      </Form>
    </Overlay>
  </div>
</template>

<script>
  import Overlay from 'src/Shared/Overlay'
  import Form    from './Search/Form'


  export default {
    data() {
      return {
        isVisible: false,
        title: "Busca"
      }
    },

    props: {
      cities: {
        type: String,
        required: true
      },

      params: {
        type: String,
        required: true
      }
    },

    components: {
      Overlay,
      Form
    },

    methods: {
      openOverlay() {
        this.isVisible = true
      },

      closeOverlay() {
        this.isVisible = false
      },
    }
  }
</script>

<style lang="scss">
</style>
