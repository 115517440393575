export function createCardToken(creditCard) {
  return new Promise((resolve, reject) => {
    PagSeguroDirectPayment.createCardToken({
      cardNumber: creditCard.number,
      brand: creditCard.brand,
      cvv: creditCard.cvv,
      expirationMonth: getMonth(creditCard.date),
      expirationYear: getYear(creditCard.date),
      success: (response) => resolve(response),
      error: (response) => reject(response),
    })
  })
}

export function listPaymentMethods(price) {
  return new Promise((resolve, reject) => {
    PagSeguroDirectPayment.getPaymentMethods({
      amount: price,
      success: (response) => resolve(response.paymentMethods),
      error: (response) => reject(response),
    })
  })
}

export function listInstallments(price) {
  return new Promise((resolve, reject) => {
    PagSeguroDirectPayment.getInstallments({
      amount: price,
      success: (response) => resolve(response.installments),
      error: (response) => reject(response),
    })
  })
}


function getMonth(date) {
  return date.split('/')[0]
}

function getYear(date) {
  return date.split('/')[1]
}
