import { http } from 'plugins/http'
import { getData } from 'utils/get'
import * as TYPES from '../../types'

export default {
  fetchCart({ commit }, { cartId }) {
    return http.get(`/spa/carts/${cartId}`)
    .then(getData)
    .then(data => Promise.resolve(data))
    .catch(() => Promise.reject('FAIL_IN_FETCH_CART'))
  },

  createCart({ commit }, payload) {
    return http.post('/spa/carts', payload)
      .then(getData)
      .then(data => Promise.resolve(data))
      .catch(() => Promise.reject('FAIL_IN_CREATE_CART'))
  },

  updateCart({ commit }, { cartId, payload}) {
    return http.patch(`/spa/carts/${cartId}`, payload)
      .then(getData)
      .then(data => Promise.resolve(data))
      .catch(() => Promise.reject('FAIL_IN_UPDATE_CART'))
  },

  initCart({ commit }, payload) {
    commit(TYPES.SET_CURRENT_CART, payload)
  },

  normalizeCart({ commit }, payload) {
    commit(TYPES.NORMALIZE_CURRENT_CART, payload)
  },

  addCartTicket({ commit }, ticket) {
    commit(TYPES.ADD_CART_TICKET_TO_CURRENT_CART, ticket)
  },

  removeCartTicket({ commit }, ticket) {
    commit(TYPES.REMOVE_CART_TICKET_TO_CURRENT_CART, ticket)
  },
}
