export default {
  enabled: true,
  code: "",
  discount: 10,
  discount_type: "percentage",
  amount: 0,
  expires_in: "",
  ticket_ids: [],
  limitless: false,
  expiresless: false,
}
