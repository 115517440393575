<template lang="html">
  <div class="root">
    <div class="photobox-list">
      <a @click="openOverlay(image)" class="photobox-card" v-for="image in images">
        <img v-lazy="image.thumb" class="photobox-image">
      </a>
    </div>

    <PhotoboxOverlay :isVisible="isVisible" :closeOverlay="closeOverlay">
      <img v-lazy="currentImage.src">
    </PhotoboxOverlay>
  </div>
</template>

<script>
  import PhotoboxOverlay from './Photobox/Overlay'

  export default {
    name: 'photobox',


    data() {
      return {
        images: [],
        isVisible: false,
        currentImage: {}
      }
    },


    mounted() {
      this.images = JSON.parse(this.photos)
      this.currentImage = this.images[0]
    },


    props: {
      photos: {
        required: true,
        default: []
      }
    },


    components: {
      PhotoboxOverlay
    },


    methods: {
      openOverlay(image) {
        this.currentImage = image
        this.isVisible = true
      },

      closeOverlay() {
        this.isVisible = false
      },
    }
  }
</script>

<style lang="scss">
</style>
