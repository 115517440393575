export const SET_FETCHING = 'MAIN_SET_FETCHING'
export const SET_MESSAGE = 'SET_MESSAGE'
export const SET_SECTORS = 'MAIN_SET_SECTORS'
export const CHANGE_SECTOR_VISIBLE = 'CHANGE_SECTOR_VISIBLE'
export const SET_PAYMENT_METHODS = 'SET_PAYMENT_METHODS'
export const SET_INSTALLMENTS = 'SET_INSTALLMENTS'
export const SET_CURRENT_PROMOTION = 'SET_CURRENT_PROMOTION'
export const SET_PROMOTION_TO_TICKETS = 'SET_PROMOTION_TO_TICKETS'
export const SET_CURRENT_CART = 'SET_CURRENT_CART'
export const ADD_CART_TICKET_TO_CURRENT_CART = 'ADD_CART_TICKET_TO_CURRENT_CART'
export const REMOVE_CART_TICKET_TO_CURRENT_CART = 'REMOVE_CART_TICKET_TO_CURRENT_CART'
export const SET_MAX_PURCHASE_TO_CURRENT_CART = 'SET_MAX_PURCHASE_TO_CURRENT_CART'
export const NORMALIZE_CURRENT_CART = 'NORMALIZE_CURRENT_CART'
export const CLEAN_CART_TICKETS_FROM_CURRENT_CART = 'CLEAN_CART_TICKETS_FROM_CURRENT_CART'
export const SET_CURRENT_TICKET_ITEM = 'SET_CURRENT_TICKET_ITEM'
export const SET_CURRENT_EVENT = 'SET_CURRENT_EVENT'
