<template lang="html">
  <div class="column column-sm">
    <h3 class="title">Dados do Pagador</h3>

    <div class="field" :class="{'has-error': $v.name.$error }">
      <label class="label is-normal">Nome Completo</label>
      <div class="control">
        <input
          class="input is-medium"
          :class="{'is-danger': $v.name.$error }"
          type="text"
          @blur="$v.name.$touch()"
          v-model="holder.name">
      </div>

      <span class="help is-danger" v-if="!$v.name.required">
        Não pode ficar em branco.
      </span>

      <span class="help is-danger" v-if="!$v.name.minWord">
        Não está completo.
      </span>
    </div>

    <div class="field" :class="{'has-error': $v.cpf.$error }">
      <label class="label is-normal">CPF/CNPJ</label>
      <div class="control">
        <input
          class="input is-medium"
          :class="{'is-danger': $v.cpf.$error }"
          type="text"
          placeholder="000.000.000-00 / 00.000.000/0000-00"
          :mask="['###.###.###-##', '##.###.###/####-##']"
          @blur="$v.cpf.$touch()"
          v-model="holder.cpf">
      </div>

      <span class="help is-danger" v-if="!$v.cpf.required">
        Não pode ficar em branco.
      </span>

      <span class="help is-danger" v-if="!$v.cpf.isCPF">
        Não é um CPF/CNPJ válido.
      </span>
    </div>

  </div>
</template>

<script>
  export default {
    props: {
      holder: {
        type: Object,
        required: true
      },

      $v: {
        type: Object,
        required: true
      }
    }
  }
</script>

<style lang="scss" scoped>
  .field > .label {
    font-weight: 400;
  }

  .title {
    color: #7F7F7F;
    font-size: 1.2rem;
    font-weight: 400;
    margin-bottom: 2rem;
  }

  .column-sm {
    flex-grow: 0.2;
    flex-basis: max-content;

    @media screen and (max-width: 768px) {
      & {
        flex-grow: 1;
      }
    }
  }

  
</style>
