export default {
  type: 'credit_card',
  sender_hash: '',
  credit_card_token: '',
  bank: '',
  installment: {
    installmentAmount: 0,
    interestFree: true,
    quantity: 1,
    totalAmount: 0,
  },
}
