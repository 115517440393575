import { isEmpty } from 'lodash'
import schema from './Schema'
import Ticket from 'src/Models/Tickets/Model'

export default class Sector {
  constructor(data = {}, eventTax = 0, customerFee = true) {
    Object.assign(this, schema, data)
    this.eventTax = eventTax
    this.customerFee = customerFee
    this.tickets = this.setTickets()
  }

  setTickets() {
    if (isEmpty(this.tickets)) {
      return []
    } else {
      return this.tickets.map(ticket =>
        new Ticket(ticket, this.eventTax, this.customerFee)
      )
    }
  }
}
