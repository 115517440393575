import { isEmpty } from 'lodash'
import schema from './Schema'
import Participant from 'src/Models/Participants/Model'

export default class TicketItem {
  constructor(data = {}) {
    Object.assign(this, schema, data)
    this.participant = this.setParticipant()
  }

  get fullName() {
    return `${this.sector_name} / ${this.name}`
  }

  get hasParticipant() {
    return !isEmpty(this.participant)
  }

  setParticipant() {
    if (this.hasParticipant) {
      return this.participant.map(p => new Participant(p))
    }
  }
}
