<template lang="html">
  <div class="notification-container">
    <Container
      :message="messages.success"
      v-show="hasSuccessMessage"
      type="success"
      @close="dismiss('success')">
    </Container>

    <Container
      :message="messages.warning"
      v-show="hasWarningMessage"
      type="warning"
      @close="dismiss('warning')">
    </Container>

    <Container
      :message="formatedErrorMessage"
      v-show="hasErrorMessages"
      type="danger"
      @close="dismiss('error')">
    </Container>

    <Container
      :message="formatedValidationMessage"
      v-show="hasValidationMessages"
      type="warning"
      @close="dismiss('validation')">
    </Container>
  </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex/dist/vuex.esm'
  import Container from './Alert/Container'


  export default {
    components: {
      Container,
    },


    computed: {
      ...mapState(['messages']),

      hasSuccessMessage() {
        return this.messages.success !== ''
      },

      hasWarningMessage() {
        return this.messages.warning !== ''
      },

      hasErrorMessages() {
        return this.messages.error.length > 0
      },

      hasValidationMessages() {
        return this.messages.validation.length > 0
      },

      formatedErrorMessage() {
        return this.messages.error.map(msg => `${msg}`).join('<br>')
      },

      formatedValidationMessage() {
        return this.messages.validation.map(msg => `${msg}`).join('<br>')
      },
    },


    methods: {
      ...mapActions(['setMessage']),

      dismiss(type) {
        let obj
        if (type === 'error') {
          obj = { type, message: [] }
        } else if (type === 'validation') {
          obj = { type, message: [] }
        } else {
          obj = { type, message: '' }
        }

        this.setMessage(obj)
      },
    },
  }
</script>

<style lang="scss" scoped>
</style>
