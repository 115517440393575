<template lang="html">
  <Avatar
    :avatar_url="avatar_url"
    :name="name"
    :name_symbol="name_symbol"
    :updateResource="uploadAvatar"
    classes="-reverse -large">
  </Avatar>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import Avatar from 'src/Shared/Avatar'


  export default {
    name: "update-avatar",


    data() {
      return {
        upload_status: "Mudar",
        isUploading: false
      }
    },


    props: {
      avatar_url: {
        type: String,
        required: true
      },

      name: {
        type: String,
        required: true
      },

      name_symbol: {
        type: String,
        required: true
      }
    },


    components: {
      Avatar
    },


    methods: {
      ...mapActions([
        'updateUser',
      ]),


      uploadAvatar(payload) {
        return this.updateUser({
          payload: { user: payload }
        })
        .then(data => Promise.resolve(data))
        .catch(error => Promise.reject(error))
      },
    }
  }
</script>
